import { AppConfigService } from 'src/app/providers/app-config.service';
import { Injectable } from '@angular/core';
import { CaseAction } from '../../modules/case/enums/case-actions';
import { environment } from 'environment/environment';

@Injectable({
  providedIn: 'root',
})
export class CasesTargetsEndpointFetcher {
  private oldBaseUrl: string = environment.serverAPIUri;
  private newBaseUrl: string = environment.casesTargetsUri;

  constructor(private appConfigService: AppConfigService) {}

  public get(action: CaseAction, params: { [key: string]: string }): string {
    // Get the cases and targets feature flag
    const casesTargetsEnabled = this.appConfigService.getConfigVariable(
      'casesTargetsEnabled'
    );
    //Based on the action and feature flag return the endpoint
    switch (action) {
      case CaseAction.CaseDeletion:
      case CaseAction.CaseRetrieval: {
        return casesTargetsEnabled
          ? `${this.newBaseUrl}/cases/${params.case_id}`
          : `${this.oldBaseUrl}/case/${params.case_id}`;
      }
      case CaseAction.CaseCreation:
      case CaseAction.CaseListing: {
        return casesTargetsEnabled
          ? `${this.newBaseUrl}/cases/`
          : `${this.oldBaseUrl}/cases`;
      }
      case CaseAction.CaseTargetsRetrieval: {
        return casesTargetsEnabled
          ? `${this.newBaseUrl}/cases/${params.case_id}/targets`
          : `${this.oldBaseUrl}/case/${params.case_id}/targets`;
      }
      // Add more actions if needed
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  }
}
