import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';
import { LayoutFacade } from '@fe-platform/core/state';
import { FaProModule } from '@fe-platform/fa-pro';
import { LetModule, PushModule } from '@ngrx/component';
import { ToastrModule } from 'ngx-toastr';
import { AccordionBodyComponent } from './accordion/accordion-content/accordion-body.component';
import { AccordionHeaderComponent } from './accordion/accordion-header/accordion-header.component';
import { AccordionComponent } from './accordion/accordion.component';
import { BadgeComponent } from './badge/badge.component';
import { CardLoaderComponent } from './card-loader/card-loader.component';
import { IsMobileDirective } from './directives/is-mobile.directive';
import { DrawerComponent, DrawerService } from './drawer';
import { IconTextButtonComponent } from './icon-text-button/icon-text-button.component';
import { ModalHeaderComponent } from './modal/modal-header/modal-header.component';
import { ModalComponent } from './modal/modal.component';
import { NoDataComponent } from './no-data/no-data.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { PanelTitleComponent } from './panel-title/panel-title.component';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { PlatformIconComponent } from './platform-icon/platform-icon.component';
import { StateIndicatorComponent } from './state-indicator/state-indicator.component';
import { TextButtonComponent } from './text-button/text-button.component';
import { TextLinkButtonComponent } from './text-link-button/text-link-button.component';
import { BaseToasterComponent } from './toaster/base-toaster.component';
import { BaseToasterService } from './toaster/base-toaster.service';
import { BaseTooltipComponent } from './tooltip/base-tooltip.component';
import { CustomTooltipDirective } from './tooltip/custom-tooltip.directive';

@NgModule({
  declarations: [
    IconTextButtonComponent,
    StateIndicatorComponent,
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionBodyComponent,
    DrawerComponent,
    PlatformIconComponent,
    ModalComponent,
    PanelTitleComponent,
    ModalHeaderComponent,
    PhoneNumberComponent,
    BaseToasterComponent,
    PaginatorComponent,
    IsMobileDirective,
    BaseTooltipComponent,
    CustomTooltipDirective,
    NoDataComponent,
    CardLoaderComponent,
    TextButtonComponent,
    BadgeComponent,
    TextLinkButtonComponent,
  ],
  imports: [
    CommonModule,
    FaProModule,
    RouterModule,
    ToastrModule.forRoot({}),
    MatPaginatorModule,
    LetModule,
    PushModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    IconTextButtonComponent,
    StateIndicatorComponent,
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionBodyComponent,
    DrawerComponent,
    PlatformIconComponent,
    ModalComponent,
    ModalHeaderComponent,
    PanelTitleComponent,
    PhoneNumberComponent,
    BaseToasterComponent,
    PaginatorComponent,
    IsMobileDirective,
    CustomTooltipDirective,
    NoDataComponent,
    CardLoaderComponent,
    TextButtonComponent,
    BadgeComponent,
    TextLinkButtonComponent,
  ],
  providers: [DrawerService, BaseToasterService, LayoutFacade],
})
export class SharedUiIntellectusModule {}
