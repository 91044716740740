import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Inject,
  OnDestroy,
  Optional,
  Output,
  QueryList,
} from '@angular/core';
import { NgFor, NgTemplateOutlet } from '@angular/common';
import { StepperItemComponent } from './stepper-item/stepper-item.component';
import { Subject, takeUntil } from 'rxjs';
import { StepperNavComponent } from './stepper-nav/stepper-nav.component';
import { StepperBtnDirective, StepperButtonAction } from './stepper-button';
import { INTELLECTUS_STEPPER_OPTIONS, StepperOptions } from './stepper.conf';

/**
 * This is a generic stepper component that allows you to provide dynamic steps
 * through content-projection. Here is an example use:
 * <intellectus-stepper>
  <intellectus-stepper-item title="targets">
    My targets content
    <intellectus-text-button
      class="ml-15"
      type="secondary"
      [size]="'small'"
      intellectusStepperBtn="next"
    >
      Next
    </intellectus-text-button>
  </intellectus-stepper-item>
  <intellectus-stepper-item title="type">
    My type content
    <intellectus-text-button
      class="ml-15"
      type="secondary"
      [size]="'small'"
      intellectusStepperBtn="prev"
    > Previous
     </intellectus-text-button>
    >
    <intellectus-text-button
      class="ml-15"
      type="secondary"
      [size]="'small'"
      intellectusStepperBtn="next"
    > Next
     </intellectus-text-button>
  </intellectus-stepper-item>
  <intellectus-stepper-item title="details"> details </intellectus-stepper-item>
  <intellectus-stepper-item title="alert"> alert </intellectus-stepper-item>
</intellectus-stepper>
 */
@Component({
  selector: 'intellectus-stepper',
  standalone: true,
  imports: [NgFor, NgTemplateOutlet, StepperItemComponent, StepperNavComponent],
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnDestroy, AfterContentInit {
  @ContentChildren(StepperItemComponent, { descendants: true })
  steps!: QueryList<StepperItemComponent>;

  @ContentChildren(StepperBtnDirective, { descendants: true })
  actionBtns?: QueryList<StepperBtnDirective>;

  @Output() closeStepper = new EventEmitter<void>();
  @Output() completeStepper = new EventEmitter<void>();

  currentStep = 0;
  stepTitles: Array<string | undefined> = [];
  private destroy$ = new Subject<void>();

  constructor(
    @Optional()
    @Inject(INTELLECTUS_STEPPER_OPTIONS)
    public stepperOptions?: StepperOptions
  ) {}

  ngAfterContentInit(): void {
    this.initSteps();
    this.initActions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectStep(index: number) {
    const currentNextBtn = this.actionBtns
      ?.toArray()
      .filter((btn) => btn.type === StepperButtonAction.NEXT)[this.currentStep];

    // Block direct navigation if "next" button remains disabled
    if (!currentNextBtn?.disabled$.value) {
      this.currentStep = index;
    }
  }

  private initSteps() {
    this.stepTitles = this.steps.map((s) => s.title);
  }

  private initActions() {
    this.actionBtns?.forEach((btn) => {
      btn.clickEmitter.pipe(takeUntil(this.destroy$)).subscribe((pivot) => {
        if (!pivot) {
          this.closeStepper.emit();
          return;
        }
        this.currentStep = this.currentStep + pivot;

        // If current step goes beyond total steps, the stepper is considered as complete
        if (this.currentStep >= this.steps.length) {
          this.completeStepper.emit();
          this.closeStepper.emit();
        }
      });
    });
  }
}
