import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { TargetService } from 'src/app/services/target/target.service';
import { IntelSearchArgTypes } from '../models/intel-search-arg-types.model';
import { AppConfigService } from './../../../providers/app-config.service';
import { BaseService } from './../../../services/base.service';
import { isValidIMEIValidateChecksum } from '@shared/util/helper';
import { uniqWith } from 'lodash';
import { KeyValue } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class InputTypeGuesserService extends BaseService {
  /* eslint-disable-next-line no-useless-escape */
  usernameSpecialCharsRegex = new RegExp(/[\d\._]/);
  curpRegex = new RegExp(
    /^([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]|[hm]{1})([AS|as|BC|bc|BS|bs|CC|cc|CS|cs|CH|ch|CL|cl|CM|cm|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne]{2})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([a-zA-Z0-9]{2})$/
  );
  emailRegexp = new RegExp(
    /* eslint-disable-next-line no-useless-escape */
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  userIdRegex = new RegExp(/^(([0-9]+)@([a-zA-Z0-9]+))$/);
  noWhitespaceRegex = new RegExp(/^\S*$/);
  /* eslint-disable-next-line no-useless-escape */
  specialCharRegex = new RegExp(/[a-zA-Z!@#%\$&()\\-\`\.+,/\"\?\=\*\'\;\:]/);
  telnoRegex = new RegExp(
    /* eslint-disable-next-line no-useless-escape */
    /^\+([0-9]+)([a-zA-Z!@#%\$&()\\-\`\.+,/\"\?\=\*\'\;\:])?$/
  );
  digitsWithTrailingCharRegex = new RegExp(
    /* eslint-disable-next-line no-useless-escape */
    /^[0-9]+([a-zA-Z0-9!@#$&()\\-`.+,/\"])?$/
  );
  voterIdRegex = new RegExp(/^[A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3}$/);
  nikRegex = new RegExp(/^\d{16}$/);
  enableCovid19MX = false;
  enableNIK = false;
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  tenantCountryCodes: number[];

  private readonly allowedUrlPrefixes = ['www.', 'chat.whatsapp.com'];

  constructor(
    private targetService: TargetService,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private appConfigService: AppConfigService
  ) {
    super(router, snackBar);
    this.enableCovid19MX =
      this.appConfigService.getConfigVariable('enableCovid19MX');
    this.enableNIK = this.appConfigService.getConfigVariable('enableNIK');
    this.tenantCountryCodes =
      this.appConfigService.getConfigVariable('tenantCountryCodes');
  }

  guessInputType(input: string): { [key: string]: any }[] {
    let possibleName = true;
    let isRealTelno = false;

    const response = [];

    if (input.startsWith('#')) {
      response.push({
        key: IntelSearchArgTypes.HASHTAG,
        value: input.split(' ')[0],
      });
      possibleName = false;
    }

    if (this.enableNIK && this.nikRegex.test(input)) {
      response.push({ key: IntelSearchArgTypes.NIK, value: input });
      possibleName = false;
    }

    if (this.emailRegexp.test(input)) {
      response.push({ key: IntelSearchArgTypes.EMAIL, value: input });
      possibleName = false;
    }

    if (this.voterIdRegex.test(input)) {
      response.push({ key: IntelSearchArgTypes.VOTER_ID, value: input });
      possibleName = false;
    }

    if (this.isValidUrl(input.trim())) {
      response.push({ key: IntelSearchArgTypes.URL, value: input });
      possibleName = false;
    }

    if (isValidIMEIValidateChecksum(parseInt(input))) {
      response.push({ key: IntelSearchArgTypes.IMEI, value: input });
      possibleName = false;
    }

    if (this.telnoRegex.test(input)) {
      // the telnoRegex is been divided into groups -> the last group checks for any additional character at the end,
      // if we find it, we slice it out of the input string
      const telnoRegexGroups = this.telnoRegex.exec(input);
      if (
        telnoRegexGroups[2] &&
        this.specialCharRegex.test(telnoRegexGroups[2])
      ) {
        input = input.slice(0, -1);
      }

      if (this.targetService.getValidPhone(this.phoneNumberUtil, input)) {
        response.push({ key: IntelSearchArgTypes.TELNO, value: input });
        possibleName = false;
        isRealTelno = true;
      }
    }

    if (this.userIdRegex.test(input)) {
      response.push({ key: IntelSearchArgTypes.USER_ID, value: input });
      possibleName = false;
    }

    if (this.enableCovid19MX && this.isValidCurp(input)) {
      response.push({ key: IntelSearchArgTypes.CURP, value: input });
      possibleName = false;
    }

    // REMOVE spaces, -, +, ), ( and 2 leading zeros
    /* eslint-disable-next-line no-useless-escape */
    let sanitized = input.replace(/[ \+\-\(\)]/g, '').replace(/^00/, '');
    if (this.digitsWithTrailingCharRegex.test(sanitized) && !isRealTelno) {
      const telnoRegexGroups = this.digitsWithTrailingCharRegex.exec(sanitized);
      if (telnoRegexGroups[1]) {
        sanitized = sanitized.slice(0, -1);
      }

      this.getPossibleTelnos(sanitized).forEach((telno) => {
        response.push({
          key: IntelSearchArgTypes.POSSIBLE_TELNO,
          value: telno,
        });
      });

      response.push({ key: IntelSearchArgTypes.USERNAME, value: input });
      possibleName = false;
    }

    if (possibleName) {
      if (this.noWhitespaceRegex.test(input)) {
        response.push({ key: IntelSearchArgTypes.USERNAME, value: input });
        if (input.substring(0, 1) === '@') {
          input = input.substring(1);
        } else if (!this.usernameSpecialCharsRegex.test(input)) {
          response.push({ key: IntelSearchArgTypes.NAME, value: input });
        }
      } else {
        const inputLength = input.split(' ').length;
        const stringsNotEmpty = input
          .split(' ')
          .every((item) => item.length > 0);
        response.push({ key: IntelSearchArgTypes.NAME, value: input });

        if (inputLength === 2 && stringsNotEmpty) {
          response.push({
            key: IntelSearchArgTypes.NAME_WITH_SURNAME,
            value: input,
          });
        } else if (inputLength === 3 && stringsNotEmpty) {
          response.push({
            key: IntelSearchArgTypes.NAME_WITH_MIDDLE_AND_SURNAME,
            value: input,
          });
        } else {
          response.push({ key: IntelSearchArgTypes.NAME, value: input });
        }
      }
    }

    return uniqWith(
      response,
      (a: KeyValue<string, string>, b: KeyValue<string, string>): boolean =>
        a.key === b.key && a.value === b.value
    );
  }

  private isValidCurp(input: string): boolean {
    return this.curpRegex.test(input);
  }

  private isValidUrl(input: string): boolean {
    let inputToCheck: string = input;
    if (this.isPossibleUrl(inputToCheck)) {
      inputToCheck = `https://${inputToCheck}`;
    }
    try {
      new URL(inputToCheck);
      return true;
    } catch (e) {
      return false;
    }
  }

  public isPossibleUrl(maybeUrl: string): boolean {
    return this.allowedUrlPrefixes.some((prefix: string): boolean =>
      maybeUrl.startsWith(prefix)
    );
  }

  /**
   * Returns a list of possible telnos based on the input
   * @param sanitizedText expected to have removed spaces, -, +, ), ( and 2 leading zeros
   */
  getPossibleTelnos(sanitizedText: string): string[] {
    const response = [];

    // IF ONLY DIGITS ADD (+) AND TRY TO PARSE AS PHONE NUMBER
    const signedSanitized = `+${sanitizedText}`;

    try {
      const target = this.phoneNumberUtil.parse(signedSanitized);
      // IF VALID PHONE, SET VALUE
      if (this.phoneNumberUtil.isValidNumber(target)) {
        response.push(signedSanitized);
      }
    } catch (e) {
      console.warn("Couldn't parse sanitized input", e);
    }
    // IF NOT VALID PHONE, TRY ADD tenantCountryCode and parse again
    this.tenantCountryCodes.forEach((code) => {
      const numberWithTenantCountryCode = `+${code}${sanitizedText}`;
      try {
        const target = this.phoneNumberUtil.parse(numberWithTenantCountryCode);
        if (this.phoneNumberUtil.isValidNumber(target)) {
          response.push(numberWithTenantCountryCode);
        }
      } catch (e) {
        console.warn("Couldn't parse numberWithTenantCountryCode", e);
      }
    });

    return response;
  }
}
