import { ExportQueue } from './export-queue.model';
import { DataSource, EntityType } from 'datalayer/models/platform-models';
import { Platform as SocialPlatform } from '@shared/schemas/common/platforms';

export interface ProfileProperty {
  value: string;
  type?: string;
  platforms?: Platform[];
  property?: string;
}

export interface Platform {
  platform: string;
  sourceUrl?: string;
  subSource?: DataSource;
}

export interface SearchIntelModel {
  createdAt: string;
  seenAt: string | undefined;
  createdBy?: string;
  id?: string;
  queryArgs?: { argValue: string; argType: string }[];
  searchByHashtag?: boolean;
  updatedAt?: string;
  profilesCount?: number;
  profiles?: Profile[];
  articles?: Article[];
  darkwebArticles?: DarkwebArticles[];
  hashtagTweets?: HashtagTweets[];
  favorite?: boolean;
  articlesCount?: number;
  queryFilters?: SearchFilters;
  queryArgsDisplay?: string[];
  groupedSearchIds?: string[];
  hashtagCount?: number;
  groupsCount?: number;
}

export interface Profile {
  id: ProfileProperty[];
  intelSearchId?: string;
  emails?: ProfileProperty[];
  usernames?: ProfileProperty[];
  addresses?: ProfileProperty[];
  imeis?: ProfileProperty[];
  countries?: ProfileProperty[];
  photos?: ProfileProperty[];
  createdAt: string;
  match?: number;
  languages?: ProfileProperty[];
  telnos?: ProfileProperty[];
  educations?: ProfileProperty[];
  names?: ProfileProperty[];
  socialProfiles?: ProfileProperty[];
  profileUrls?: ProfileProperty[];
  jobs?: ProfileProperty[];
  gender?: ProfileProperty[];
  dob?: ProfileProperty[];
  platform?: string;
  lastSeen?: Date;
  about?: { value: string; platforms: string[] }[];
  updatedAt: string;
  hideProfile?: boolean;
  voterId?: string;
  relevanceScore?: number;
  landTelnos?: ProfileProperty[];
  source?: string;
  age?: number | ProfileProperty[];
  photoUrls?: [];
  type?: ProfileProperty[] | string;
  ife?: ProfileProperty[];
  curp?: ProfileProperty[];
  nik?: ProfileProperty[];
  rfc?: ProfileProperty[];
  motherNames?: ProfileProperty[];
  fatherNames?: ProfileProperty[];
  skills?: ProfileProperty[];
  courses?: ProfileProperty[];
  groups?: ProfileProperty[];
  industry: ProfileProperty[];
  vehicleYear?: ProfileProperty[];
  vehicleIdNumber?: ProfileProperty[];
  vehicleModel?: ProfileProperty[];
  vehicleMake?: ProfileProperty[];
  associatedDomain?: ProfileProperty[];
  fanOf?: ProfileProperty[];
  otherTags?: ProfileProperty[];
  homeLastSaleDate?: ProfileProperty[];
  propertyType?: ProfileProperty[];
  volunteering?: ProfileProperty[];
  ipAddresses?: ProfileProperty[];
  availableAdvancedWebint?: boolean;
  placeOfBirth?: ProfileProperty[];
  nationalities?: ProfileProperty[];
  height?: ProfileProperty[];
  weight?: ProfileProperty[];
  hairColor?: ProfileProperty[];
  eyesColor?: ProfileProperty[];
  otherInfo?: ProfileProperty[];
  checkIn?: CheckInProfileProperty[];
}

export interface Article {
  url: string;
  title?: string;
  content?: string;
  articleId?: string;
  id?: string;
  engines?: string[];
  validAt?: string;
  author?: string;
  intelSearchId?: string;
  mostRelevantScore?: number;
  platform?: string;
  siteName?: string;
  articleSnapshotUrl?: string;
  imageUrl?: string;
  thumbnailUrl?: string;
  photoUrl?: string;
}
export interface SearchProfileResult {
  currentPage: number;
  limit: number;
  result: Profile[];
  totalItems: number;
  totalPages: number;
}

export interface SearchArticleResult {
  articles: Article[];
  pagination: {
    currentPage: number;
    limit: number;
    result: Profile[];
    totalItems: number;
    totalPages: number;
  };
}

export interface SearchDarkWebResult {
  articles: DarkwebArticles[];
  pagination: {
    currentPage: number;
    limit: number;
    result: Article[];
    totalItems: number;
    totalPages: number;
  };
}

export interface DarkwebArticles {
  title?: string;
  url: string;
  fileType?: string;
  articleSnapshotUrl: string;
  validAt?: string;
  articleId?: string;
  platform?: string;
  id?: string;
  articleDate?: string;
  articleType?: string;
  intelSearchId?: string;
  siteName?: string;
  domain?: string;
}

export interface SearchGroupResult {
  groups: Group[];
  pagination: {
    currentPage: number;
    limit: number;
    result: Article[];
    totalItems: number;
    totalPages: number;
  };
}

export interface Group {
  intelSearchId: string;
  type: EntityType;
  name: string;
  description: string;
  participantsCount: number;
  messagesCount: number;
  imageId: string;
  source: DataSource;
  groupLink: string;
  sourceEntity: {
    createdAt: string;
    id: string;
  };
  participants: {
    telno: string;
    name: string;
    imageId: string;
  }[];
  platform: SocialPlatform;
}

interface HashtagTweets {
  intelSearchId?: string;
  platform?: string;
  profileId?: string;
  url?: string;
  profilePhoto?: string;
  name?: string;
  id?: string;
  updatedAt?: string;
  hashtagTweetsList?: Tweets[];
}

interface Tweets {
  content?: string;
  tweetUrl?: string;
  createdAt?: string;
  coordinates?: any;
  geo?: any;
  place?: any;
  favoriteCount?: number;
  retweetCount?: number;
  hashtagPhotosUrls?: string[];
}

export enum IntelResultsIcons {
  names = 'account_circle',
  telnos = 'phone',
  usernames = 'supervised_user_circle',
  emails = 'alternate_email',
  jobs = 'work',
  addresses = 'location_on',
  educations = 'school',
  countries = 'flag',
  profileUrls = 'link',
  landTelnos = 'phone',
}

export enum DisplayTargetFields {
  PHOTOS = 'photos',
  NAMES = 'names',
  TELNOS = 'telnos',
  USERNAMES = 'usernames',
  PROFILEURLS = 'profileUrls',
  EMAILS = 'emails',
  JOBS = 'jobs',
  ADDRESSES = 'addresses',
  EDUCATIONS = 'educations',
  ABOUT = 'about',
  GENDER = 'gender',
  COUNTRIES = 'countries',
  IMEIS = 'imeis',
  VOTERID = 'voterId',
  LANDTELNOS = 'landTelnos',
  TELECOM_PROVIDERS = 'telecomProviders',
  STATUS = 'status',
  RECOVERY_ACCOUNT = 'recoveryAccount',
  DOB = 'dob',
  MOTHER_NAMES = 'motherNames',
  FATHER_NAMES = 'fatherNames',
  CURP = 'curp',
  NIK = 'nik',
  IFE = 'ife',
  RFC = 'rfc',
  IP_ADDRESSES = 'ipAddresses',
  GROUPS = 'groups',
  COURSES = 'courses',
  SKILLS = 'skills',
  VOLUNTEERING = 'volunteering',
  INDUSTRY = 'industry',
  PROPERTY_TYPE = 'propertyType',
  HOME_LAST_SALE_DATE = 'homeLastSaleDate',
  FAN_OF = 'fanOf',
  OTHER_TAGS = 'otherTags',
  VEHICLE_MODEL = 'vechileModel',
  VEHICLE_MAKE = 'vehicleMake',
  VEHICLE_ID_NUMBER = 'vehicleIdNumber',
  VEHICLE_YEAR = 'vehicleYear',
  ASSOCIATED_DOMAIN = 'associatedDomain',
  FAMILY_RELATIONS = 'familyRelations',
  WORK_RELATIONS = 'workRelations',
  FRIEND_RELATIONS = 'friendRelations',
  OTHER_RELATIONS = 'otherRelations',
  PLACE_OF_BIRTH = 'placeOfBirth',
  NATIONALITIES = 'nationalities',
  EYESCOLOR = 'eyesColor',
  HAIRCOLOR = 'hairColor',
  WEIGHT = 'weight',
  HEIGHT = 'height',
  DEVICES_INFO = 'devicesInfo',
}

export interface DataIMExport {
  profileIds?: string[];
  queryArgs?: { argValue: string; argType: string }[];
}

export class SearchFilters {
  location?: { country?: string; city?: string; rawAddress?: string };
  age?: Age;
  connections?: string[];
  jobs?: string[];
  searchText?: string[];
  searchByKeyword?: boolean;
}
export interface Age {
  min?: number;
  max?: number;
}
export interface Pagination {
  currentPage?: number;
  limit?: number;
  totalItems?: number;
  totalPages?: number;
}

export interface SearchTypeHistoryFilter {
  argType: string[];
  total: number;
  show?: boolean;
}

export interface UserTypeHistoryFilter {
  user: string;
  total: number;
  show?: boolean;
}

export interface SearchIntelResult {
  queries: SearchIntelModel[];
  pagination: Pagination;
  searchType: SearchTypeHistoryFilter[];
  userType: UserTypeHistoryFilter[];
}

export enum SearchLabels {
  NAME = 'Full Name',
  NAME_WITH_SURNAME = 'Name Surname',
  NAME_WITH_MIDDLE_AND_SURNAME = 'Name Middle Surname',
  USERNAME = 'Username',
  IMAGE = 'Image',
  URL = 'URL',
  EMAIL = 'Email',
  PHONE = 'Phone',
  POSSIBLE_TELNO = 'Possible Phone',
  USER_ID = 'User id',
  VOTER_ID = 'Voter id',
  CURP = 'CURP',
  NIK = 'NIK',
  HASHTAG = 'Keyword',
  IMEI = 'IMEI',
}

export enum IntelSearchType {
  GE0 = 'GE0',
  OSINT = 'OSINT',
  ADINT = 'ADINT',
}
export interface ConfirmationPopupModel {
  id: string;
  label: string;
  content: string;
  okText: string;
  cancelText: string;
}

export enum IntelFilters {
  LOCATION = 'Location',
  AGE = 'Age',
  MOREFILTERS = 'MoreFilters',
  CONNECTIONS = 'Connections',
  JOBS = 'Jobs',
  FREETEXTS = 'Freetexts',
}

export enum SearchResultTabs {
  PROFILE = 'Profile',
  OPENWEB = 'OpenWeb',
  DARKWEB = 'DarkWeb',
  KEYWORDS = 'Keywords',
  GROUPS = 'Groups',
}

export enum EnhanceProfileAction {
  ADVANCED = 'AdvancedOsint',
  DEEP = 'DeepOsint',
}

export interface EnhanceProfilePopupModel {
  enableAdvancedOsint: boolean;
  enableDeepOsint: boolean;
  fromTarget: boolean;
  credits?: number;
}

export interface IntelSearchDashboardStructure {
  query: SearchIntelModel;
  showFiltersPanel: boolean;
  totalItemsCount?: number;
  totalProfiles?: number;
  totalOpenWeb?: number;
  totalDarkWeb?: number;
  totalGroups?: number;
  totalProfilesComplete?: boolean;
  totalOpenWebComplete?: boolean;
  totalDarkWebComplete?: boolean;
  totalGroupsComplete?: boolean;
  currentQueue?: {
    intelSearchId?: string;
    intelSearchQueryArgs?: { argValue: string; argType: string }[];
    exportQueueData: ExportQueue[][];
  };
  currentDeletedQueueInfo?: string[];
  profileFilters?: { types?: string[]; values?: { [key: string]: string[] } };
  articleFilters?: { [key: string]: string[] };
  groupsFilters?: { [key: string]: string[] };
  darkWebArticleFilters?: {
    siteName: string;
    total: number;
    selected?: boolean;
  }[];
  selectedTab?: SearchResultTabs;
  queueValues: {};
  showNoResult: boolean;
  navBarIdentifier: string;
}

export interface QueryArg {
  argType: string;
  argValue: string;
  platform?: string | null;
}

export interface SingleSearchIntelApiResponse {
  data: null;
  result: SearchIntelModel;
}

export interface IntelSearchField {
  value: string;
  label: SearchLabels;
  countryCode?: string;
  filename?: string;
  displayName?: string;
}

export enum IntelSearchMode {
  Search = 'Search',
  Locate = 'Locate',
  SearchAndLocate = 'SearchAndLocate',
}

export interface CheckIn {
  name: string;
  query_date: Date;
  visited_at: Date;
  category: string;
  address: string;
  coordinates: {
    type: string;
    coordinates: number[];
  };
  review: string;
  media?: CheckInMedia[];
}

export interface CheckInMedia {
  image_url: string;
}

export type CheckInProfileProperty = Omit<ProfileProperty, 'value'> & {
  value: CheckIn;
};

export enum StoredModalDataField {
  SearchFilters,
  ArticleFilters,
  SearchResult,
  SearchDashboard,
  Queue,
  SearchHistory,
  SearchHistoryFilters,
}
