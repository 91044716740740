import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { EntityType } from '@trg-commons/data-models-ts';
import { Profile } from 'datalayer/models/platform-models';
import { DataSource } from 'datalayer/models/platform-models/enums/data.source';
import { RequestOptions } from 'datalayer/services/base';
import { RefreshLocalCacheService } from 'datalayer/services/base/refresh-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ProfileService } from './../../data-layer/services/social/profile/profile.service';
import { MergeDataHelperService } from './merge-data-helper.service';

@Injectable({
  providedIn: 'root',
})
export class IMDataService extends BaseService {
  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private profileService: ProfileService,
    private mergeDataHelperService: MergeDataHelperService,
    private refreshLocalCacheService: RefreshLocalCacheService
  ) {
    super(router, snackBar);
  }

  getIMData(
    target: TargetItem,
    targetId: string
  ): Observable<Partial<TargetItem>> {
    const IMfilter: RequestOptions = {
      filters: {
        source: [
          DataSource.Skype,
          DataSource.SocialSearch,
          DataSource.Telegram,
          DataSource.Tinder,
          DataSource.Truecaller,
          DataSource.CallerID,
          DataSource.GetContact,
          DataSource.Viber,
          DataSource.Wechat,
          DataSource.WhatsApp,
          DataSource.IndoDB,
        ],
        targetId,
        type: EntityType.Profile,
      },
    };

    return this.profileService.getAll(IMfilter).pipe(
      map((res) => Object.values(res)),
      map((profileData: Profile[]) => {
        return profileData.reduce((acc: Partial<TargetItem>, data: Profile) => {
          acc = this.mergeDataHelperService.mergeData(acc, data);

          return acc;
        }, target);
      }, {})
    );
  }

  refreshIM(deletedValueTypes: string[], reEnabledValueType: string[]) {
    if (
      deletedValueTypes?.includes('telnos') ||
      reEnabledValueType?.includes('telnos')
    ) {
      [
        DataSource.WhatsApp,
        DataSource.Telegram,
        DataSource.Truecaller,
        DataSource.CallerID,
        DataSource.GetContact,
        DataSource.Skype,
        DataSource.Viber,
        DataSource.Wechat,
        DataSource.Tinder,
        DataSource.SocialSearch,
        DataSource.IndoDB,
      ].forEach((source) =>
        this.refreshLocalCacheService.emitRefreshObservables(source)
      );
    }
  }
}
