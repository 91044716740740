import { Directive, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition,
  MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
} from '@angular/material/legacy-snack-bar';
import { of, Subject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DependencyInjectionService } from 'src/app/services/dependency-injection.service';

@Directive()
export class BaseComponent implements OnInit, OnDestroy {
  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';
  subscriptions: Subscription[] = [];
  intelSubscriptions: Subscription[] = [];
  loading = false;
  public destroyed$ = new Subject();

  // Injected deps
  public snackBar: MatSnackBar;

  set subscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  constructor() {
    // We now get the dependencies from our injector instead of passing them up from the childre
    // (that would not be a scaleable approach)
    const dependencyInjector = DependencyInjectionService.getInjector();
    if (dependencyInjector) {
      this.snackBar = dependencyInjector.get(MatSnackBar);
    }
  }

  protected addIntelSubscriptions(subscription: Subscription) {
    this.intelSubscriptions.push(subscription);
  }

  protected resetIntelSubscriptions() {
    (this.intelSubscriptions || []).forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    /* eslint-disable-next-line no-empty-function */
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
    this.resetIntelSubscriptions();
    this.destroyed$.next(true);
  }

  setLoading(loading = true, delayMillis = 0, callback?: () => void) {
    of(null)
      .pipe(delay(delayMillis))
      .subscribe(() => {
        this.loading = loading;
        if (callback) {
          callback();
        }
      });
  }

  protected showMessage(
    msg: string,
    okText = 'OK',
    preferredDuration?: number,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition
  ) {
    this.snackBar.open(msg, okText, {
      duration: preferredDuration || 3000,
      horizontalPosition: preferredHorizontalPosition,
      verticalPosition: preferredVerticalPosition,
      panelClass: ['custom-snackbar'],
    });
  }
}
