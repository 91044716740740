import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatLegacyProgressSpinner as MatProgressSpinner } from '@angular/material/legacy-progress-spinner';

@Directive({
  selector: '[btnSpinner]',
})
export class ButtonSpinnerDirective implements OnChanges {
  @Input('btnSpinner') loading = false;
  @Input() btnSpinnerColor: ThemePalette = 'primary';

  private element: HTMLButtonElement;
  private spinnerRef: ComponentRef<MatProgressSpinner>;
  private spinnerContainer: ComponentRef<MatIcon>;

  constructor(
    elRef: ElementRef,
    public vcRef: ViewContainerRef,
    private renderer: Renderer2
  ) {
    this.element = elRef.nativeElement;
  }

  startSpinner() {
    // Create components
    this.spinnerRef = this.vcRef.createComponent(MatProgressSpinner);
    this.spinnerContainer = this.vcRef.createComponent(MatIcon);

    this.renderer.addClass(
      this.spinnerContainer.location.nativeElement,
      'btn-spinner'
    );

    // Configure spinner
    this.spinnerRef.instance.diameter = 20;
    this.spinnerRef.instance.mode = 'indeterminate';
    this.spinnerRef.instance.color = this.btnSpinnerColor;

    this.renderer.appendChild(
      this.spinnerContainer.location.nativeElement,
      this.spinnerRef.location.nativeElement
    );

    this.renderer.appendChild(
      this.element,
      this.spinnerContainer.location.nativeElement
    );

    this.element.disabled = true;
  }

  stopSpinner() {
    if (!this.spinnerContainer) {
      return;
    }

    setTimeout(() => {
      this.renderer.removeChild(
        this.element,
        this.spinnerContainer.location.nativeElement
      );
      this.element.disabled = false;
    }, 600);
  }

  ngOnChanges(): void {
    if (this.loading) {
      this.startSpinner();
    } else {
      this.stopSpinner();
    }
  }
}
