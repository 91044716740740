import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { Themes } from 'src/app/shared/models/skins.model';
import { NavbarMenuItem } from './nav-bar.model';
import { QueryService } from 'src/app/services/query/query.service';
import {
  DashboardService,
  DashboardView,
} from 'src/app/services/dashboard/dashboard.service';
import { NavigationEnd, Router } from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  skipWhile,
  startWith,
  take,
} from 'rxjs/operators';
import { WebintDisabledService } from 'src/app/shared/components/webint-disabled-modal/webint-disabled.service';
import { NavMenuSubItemComponent } from './nav-menu-sub-item/nav-menu-sub-item.component';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';
import { FontAwesomeFamily } from '@fe-platform/shared-ui/intellectus';
import { BehaviorSubject } from 'rxjs';
import { NewDiscoveryToggleComponent } from 'src/app/modules/trg-common/components/new-discovery-toggle/new-discovery-toggle.component';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss'],
})
export class NavbarMenuComponent implements OnInit, OnChanges {
  @ViewChildren('submenu') subMenus: QueryList<NavMenuSubItemComponent>;
  @ViewChildren('toggleButton', { read: ViewContainerRef })
  container: QueryList<ViewContainerRef>;

  @Input()
  public isMobile: boolean;

  @Output()
  public readonly onMenuItemClick: EventEmitter<void> = new EventEmitter<void>();

  private timedOutCloser: NodeJS.Timeout;

  constructor(
    private appConfigService: AppConfigService,
    private queryService: QueryService,
    private dashboardService: DashboardService,
    private router: Router,
    private webintDisabledService: WebintDisabledService
  ) {
    this.isLocatingDisabled = this.appConfigService.getConfigVariable(
      'disableLocatingFunctionality'
    );
  }
  actualTheme: Themes;
  themes = Themes;
  private activeMenuItems: BehaviorSubject<NavbarMenuItem[]> =
    new BehaviorSubject([]);
  public activeMenuItems$ = this.activeMenuItems.asObservable();
  public menuItems: NavbarMenuItem[] = [];
  isLocatingDisabled = false;

  public ngOnInit(): void {
    this.actualTheme = this.appConfigService.getConfigVariable('theme');
    this.checkActiveRoute();
    this.activeMenuItems.subscribe((menuItems) => {
      this.buildMenuToggleButtons();
    });
    this.checkAdIntV2Enabled();
  }

  ngOnChanges(): void {
    this.buildMenu();
  }

  ngAfterViewInit() {
    this.buildMenuToggleButtons();
  }

  private buildMenuToggleButtons(): void {
    const menuItems = this.activeMenuItems.getValue();
    menuItems.forEach((menuItem) => {
      if (!menuItem.isToggleMenu) return;
      if (!this.container) return;
      setTimeout(() => {
        this.container.forEach((toggleButton) => {
          if (toggleButton.element.nativeElement.id == menuItem.name) {
            toggleButton.clear();
            const componentRef = toggleButton.createComponent(
              menuItem.toggleButtonComponent
            );
            componentRef.instance['displayLabel'] = false;
          }
        });
      }, 0);
    });
  }

  private checkAdIntV2Enabled() {
    this.appConfigService.authenticatedConfigLoaded$
      .pipe(
        untilDestroyed(this),
        skipWhile((configLoaded) => !configLoaded),
        take(1)
      )
      .subscribe({
        next: (_) => {
          const menuItems = this.activeMenuItems.getValue();
          const adIntMenuItemIndex = menuItems.findIndex(
            (menuItem) => menuItem.link === '/account/adint'
          );
          menuItems[adIntMenuItemIndex].visible =
            this.appConfigService.getConfigVariable('enableAdIntV2');
          this.activeMenuItems.next(menuItems);
        },
      });
  }

  checkActiveRoute(): void {
    this.router.events
      .pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        startWith(this.router),
        distinctUntilChanged()
      )
      .subscribe((event) => {
        const isActive = this.isTargetsOrCasesOrInvestigationRoute(event.url);
        const navItemsCopy = this.activeMenuItems
          .getValue()
          .map((item) => ({ ...item, routeActive: false }));
        if (isActive) {
          navItemsCopy.find((item) => item.name === 'Core').routeActive = true;
        }
        if (event.url === `/${ApplicationMainPageUrls.WEBINT}/history`) {
          navItemsCopy.find((item) => item.name === 'Webint').routeActive =
            true;
        }
        if (
          ['/discovery', '/account/discovery'].some((url) =>
            event.url.includes(url)
          )
        ) {
          navItemsCopy.find((item) => item.name === 'Discovery').routeActive =
            true;
        }
        this.activeMenuItems.next(navItemsCopy);
      });
  }

  buildMenu(): void {
    const showInvestigation =
      this.appConfigService.getConfigVariable('enableInvestigationModule') &&
      !this.appConfigService.getConfigVariable(
        'disableNavigationToInvestigation'
      );
    const enableDiscoveryV2 =
      this.appConfigService.getConfigVariable('enableDiscoveryV2');
    const menus: NavbarMenuItem[] = [
      {
        name: 'Home',
        link: '/webint',
        angulartics2On: 'click',
        angularticsAction: 'matomo.actions.discovery',
        angularticsCategory: 'matomo.categories.navBar',
        visible: this.isMobile,
        faIcon: {
          name: 'house',
          family: FontAwesomeFamily.REGULAR,
        },
        onClick: () => this.onMenuItemClick.emit(),
      },
      {
        name: 'Core',
        svgIcon: 'analysis-menu',
        angulartics2On: '',
        link: null,
        angularticsAction: '',
        angularticsCategory: '',
        visible: !this.isMobile,
        children: [
          {
            name: 'Targets',
            link: `/${ApplicationMainPageUrls.CORE}/targets`,
            svgIcon: 'target-menu',
            description: 'List of all targets',
            visible: true,
          },
          {
            name: 'Cases',
            link: `/${ApplicationMainPageUrls.CORE}/cases`,
            svgIcon: 'cases-menu',
            description: 'List of all cases',
            visible: true,
          },
          {
            name: 'Investigation',
            link: `/${ApplicationMainPageUrls.CORE}/investigations`,
            svgIcon: 'investigation-menu',
            description: 'Tool to analyze different types of intelligence',
            visible: showInvestigation,
            betaSign: true,
          },
          {
            name: 'Crypto',
            link: `/${ApplicationMainPageUrls.CORE}/crypto`,
            svgIcon: 'wallet',
            description: '"Crypto" - List of exchange apps users ids',
            visible: this.appConfigService.getConfigVariable('enableCrypto'),
            betaSign: true,
          },
        ],
      },
      {
        name: 'Discovery',
        svgIcon: 'geolocation',
        link: '/discovery',
        angulartics2On: 'click',
        angularticsAction: 'matomo.actions.discovery',
        angularticsCategory: 'matomo.categories.navBar',
        visible: !this.isLocatingDisabled,
        onClick: () => this.onNavigateToQuery(),
        isToggleMenu: this.isMobile && enableDiscoveryV2,
        toggleButtonComponent: NewDiscoveryToggleComponent,
      },
      {
        name: 'Webint',
        svgIcon: 'webint-menu',
        link: null,
        angulartics2On: '',
        angularticsAction: '',
        angularticsCategory: '',
        visible: true,
        onClick: () => this.onWebint(),
      },
      {
        name: 'Call Logs',
        svgIcon: 'call-logs-menu',
        link: `/${ApplicationMainPageUrls.CORE}/call-logs`,
        angulartics2On: '',
        angularticsAction: '',
        angularticsCategory: '',
        visible: !this.isMobile,
      },

      {
        name: 'Virtual ID',
        svgIcon: 'avatars',
        link: '/virtual-id',
        angulartics2On: '',
        angularticsAction: '',
        angularticsCategory: '',
        betaSign: true,
        visible:
          this.appConfigService.getConfigVariable('enableAvatars') &&
          !this.isMobile,
      },
      {
        name: 'Adint',
        svgIcon: 'adint-map',
        link: '/adint',
        angulartics2On: 'click',
        angularticsAction: 'matomo.actions.adint',
        angularticsCategory: 'matomo.categories.navBar',
        betaSign: true,
        visible:
          this.appConfigService.getConfigVariable('enableAdInt') &&
          !this.isMobile,
      },
      {
        name: 'Adint v.2',
        svgIcon: 'adint-map',
        link: '/account/adint',
        angulartics2On: 'click',
        angularticsAction: 'matomo.actions.adintv2',
        angularticsCategory: 'matomo.categories.navBar',
        betaSign: true,
        visible: this.appConfigService.getConfigVariable('enableAdIntV2'),
      },
      {
        name: 'Tactical',
        link: '/tactical',
        angulartics2On: '',
        angularticsAction: '',
        angularticsCategory: '',
        visible:
          this.appConfigService.getConfigVariable('enableTactical') &&
          !this.isMobile,
        onClick: () => this.onNavigateToTactical(),
      },
      {
        name: 'Darkweb',
        link: '/darkweb',
        angulartics2On: '',
        angularticsAction: '',
        angularticsCategory: '',
        visible:
          this.appConfigService.getConfigVariable('enableDarkwebLive') &&
          !this.isMobile,
      },
    ];
    this.activeMenuItems.next(menus.filter((item) => item.visible));
  }

  handleClick(item: NavbarMenuItem): void {
    if (item.onClick) {
      item.onClick();
    }
  }

  onWebint(): void {
    const disableWebintActions = this.appConfigService.getConfigVariable(
      'disableWebintActions'
    );
    if (disableWebintActions) {
      this.webintDisabledService.openWebintDisabledModal();
      return;
    }
    this.router.navigate([`/${ApplicationMainPageUrls.WEBINT}/history`]);
    this.onMenuItemClick.emit();
  }

  onNavigateToQuery(): void {
    this.queryService.isAutoFillQueries.next(false);
    this.dashboardService.componentsView.next(DashboardView.LOG);
    this.onMenuItemClick.emit();
  }

  onNavigateToTactical(): void {
    this.dashboardService.componentsView.next(DashboardView.LOG);
  }

  private isTargetsOrCasesOrInvestigationRoute(url: string): boolean {
    return (
      url === `/${ApplicationMainPageUrls.CORE}` ||
      url === `/${ApplicationMainPageUrls.CORE}/targets` ||
      url === `/${ApplicationMainPageUrls.CORE}/cases` ||
      url.includes('/targets') ||
      url.includes('/case') ||
      url.includes('/investigations')
    );
  }

  mouseEnter(trigger: MatMenuTrigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger: MatMenuTrigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }

  onMenuItemMouseOver(): void {
    this.subMenus.forEach((subMenu) => subMenu.menuTrigger?.closeMenu());
  }
}
