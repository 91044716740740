import { Injectable } from '@angular/core';
import {
  AdIntSessionQueryEvent,
  AdIntSessionQueryStatus,
  AdIntWSMessage,
} from '../models/AdIntWSMessage.model';
import { Router } from '@angular/router';
import { BaseToasterService } from '@fe-platform/shared-ui/intellectus';
import { TranslationService } from 'src/app/services/translation/translation.service';

@Injectable({
  providedIn: 'root',
})
export class AdIntWSService {
  constructor(
    private router: Router,
    private toasterService: BaseToasterService,
    private translationService: TranslationService
  ) {}

  handleWSMsg(msg: AdIntWSMessage<any>) {
    switch (msg.type) {
      case 'ADINT_SESSION_QUERY':
        this.handleSessionQuery(msg as AdIntWSMessage<AdIntSessionQueryEvent>);
        break;
      default:
        break;
    }
  }

  private handleSessionQuery(msg: AdIntWSMessage<AdIntSessionQueryEvent>) {
    if (msg.payload.status === AdIntSessionQueryStatus.Complete) {
      this.toasterService.show({
        title: this.translationService.translate('Session results are ready'),
        actions: [
          {
            label: this.translationService.translate('View'),
            callback: () =>
              this.router
                .navigateByUrl('/account', { skipLocationChange: true })
                .then(() => {
                  this.router.navigate(
                    ['account', 'adint', msg.payload.caseId ?? 'draft'],
                    {
                      queryParams: {
                        selected: msg.payload.sessionId,
                      },
                    }
                  );
                }),
          },
        ],
      });
    }
  }
}
