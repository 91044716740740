import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatLegacyPaginator as MatPaginator,
  LegacyPageEvent as PageEvent,
} from '@angular/material/legacy-paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'intellectus-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements AfterViewInit {
  @Input() pageSize!: number;
  @Input() pageSizeOptions!: number[];
  @Input() pageIndex!: number;
  @Input() length!: number;
  @ViewChild(MatPaginator) matPaginator!: MatPaginator;
  @Output() pageEvent: EventEmitter<PageEvent> = new EventEmitter();

  ngAfterViewInit(): void {
    this.matPaginator?.page
      .pipe(
        tap((pageEvent) => this.pageEvent.next(pageEvent)),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
