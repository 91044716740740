import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ImageService } from 'src/app/services/image/image.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Angulartics2 } from 'angulartics2';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import {
  BillingActions,
  BillingActionType,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { BillingService } from 'src/app/services/billing/billing.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { urlRegex } from '@app/validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';

export interface SearchByImageModalPayload {
  name: string;
  filename: string;
  displayName: string;
  imageUrl?: string;
}

enum FormFieldNames {
  base64File = 'base64File',
  imageUrl = 'imageUrl',
  name = 'name',
}

@UntilDestroy()
@Component({
  selector: 'app-searchby-image',
  templateUrl: './searchby-image.component.html',
  styleUrls: ['./searchby-image.component.scss'],
})
export class SearchbyImageComponent implements OnInit {
  @ViewChild('fileDropRef')
  public fileDropEl: ElementRef;

  public files: File[] = [];

  public readonly form: UntypedFormGroup = this.fb.group({
    [FormFieldNames.base64File]: [null, [this.fileValidator()]],
    [FormFieldNames.imageUrl]: [null, [this.urlValidator()]],
    [FormFieldNames.name]: [null, [this.nameValidator()]],
  });

  public billingPlan$: Observable<
    BillingPlan<BillingActions, BillingActionType>
  >;

  public selectedImageBase64: string;

  private base64Regex = new RegExp(
    /* eslint-disable-next-line no-useless-escape */
    /^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
  );

  public showLoader = false;

  public readonly fields: typeof FormFieldNames = FormFieldNames;

  constructor(
    public readonly dialogRef: MatDialogRef<SearchbyImageComponent>,
    private readonly imageService: ImageService,
    private readonly translationService: TranslationService,
    private readonly billingService: BillingService,
    private readonly fb: UntypedFormBuilder,
    private readonly angulartics2: Angulartics2,
    private readonly snackBar: MatSnackBar,
    private userBillingService: UserBillingService
  ) {
    this.listenFormChange();
  }

  ngOnInit(): void {
    this.billingPlan$ = this.billingService
      .getBillingPlan()
      .asObservable()
      .pipe(
        map((plan) => this.userBillingService.mapOfflineToOnlineCosts(plan))
      );
  }

  forbiddenUrlValidator(control: AbstractControl): ValidationErrors {
    const shouldBeValidated = !!this.imageUrlControl.value;
    const forbidden: boolean =
      this.base64Regex.test(control.value) || urlRegex.test(control.value);
    return !forbidden && shouldBeValidated
      ? { forbiddenUrl: { value: control.value } }
      : null;
  }

  /**
   * on file drop handler
   */
  onFileChange($event: FileList) {
    this.angulartics2.eventTrack.next({
      action: matomoActions.searchByImage,
      properties: {
        category: matomoCategories.landingPage,
      },
    });
    if (
      $event?.length &&
      ['image/png', 'image/x-png', 'image/jpg', 'image/jpeg'].includes(
        $event[0].type
      )
    ) {
      this.prepareFilesList($event);
      this.toBase64(this.files[0]).then((data: string) => {
        this.selectedImageBase64 = data;
        this.fileControl.setValue(data);
      });
    } else {
      //   console.log('Uploaded file is not an image');
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files = [];
    this.selectedImageBase64 = '';
    this.fileControl.patchValue(null);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: FileList) {
    for (const item of Array.from(files)) {
      this.files = [];
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = '';
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  search(includeName: boolean) {
    this.angulartics2.eventTrack.next({
      action: matomoActions.searchByImage,
      properties: {
        category: matomoCategories.landingPage,
      },
    });

    const image: string = this.imageUrlControl.value || this.fileControl.value;

    const isBase64Data = image.includes('data:image');
    const result: SearchByImageModalPayload = {
      name: includeName ? this.nameControl.value : undefined,
      filename: this.files?.length ? this.files[0].name : image,
      displayName: image || undefined,
    };

    this.getImageUrl(image, isBase64Data, result);
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  getImageUrl(
    image: string,
    isBase64Data: boolean,
    result: SearchByImageModalPayload
  ) {
    this.showLoader = true;
    this.imageService
      .getImageUrl(image, isBase64Data)
      .pipe(untilDestroyed(this))
      .subscribe((imageUrl: string) => {
        this.showLoader = false;

        if (imageUrl) {
          this.dialogRef.close({ ...result, imageUrl });
        } else {
          this.showMessage(
            this.translationService.translate(
              'Something went wrong. Please try again.'
            )
          );
        }
      }),
      () => {
        this.showMessage(
          this.translationService.translate(
            'Something went wrong. Please try again.'
          )
        );
        this.showLoader = false;
        this.dialogRef.close();
      };
  }

  showMessage(msg: string, okText = 'OK') {
    this.snackBar.open(msg, okText, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar'],
    });
  }

  public get fileControl(): AbstractControl {
    return this.form?.get(FormFieldNames.base64File);
  }

  public get imageUrlControl(): AbstractControl {
    return this.form?.get(FormFieldNames.imageUrl);
  }

  public get nameControl(): AbstractControl {
    return this.form?.get(FormFieldNames.name);
  }

  private listenFormChange(): void {
    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: Record<FormFieldNames, string>): void => {
        if (!value.base64File && !value.imageUrl) {
          this.fileControl.enable({ onlySelf: true, emitEvent: false });
          this.imageUrlControl.enable({ onlySelf: true, emitEvent: false });
        } else if (value.base64File) {
          this.imageUrlControl.disable({ onlySelf: true, emitEvent: false });
        } else if (value.imageUrl) {
          this.fileControl.disable({ onlySelf: true, emitEvent: false });
        }
      });
  }

  private fileValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.disabled) {
        return null;
      }

      return Validators.required(control);
    };
  }

  private urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.disabled) {
        return null;
      }

      return (
        Validators.required(control) || this.forbiddenUrlValidator(control)
      );
    };
  }

  private nameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        (!this.fileControl?.value && !this.imageUrlControl?.value) ||
        !!this.imageUrlControl?.value ||
        !!this.fileControl.value
      ) {
        return Validators.required(control);
      }

      return null;
    };
  }
}
