import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { CQRSBaseEvent, EventChannel } from '@trg-commons/gio-data-models-ts';
import { camelCase, cloneDeep, groupBy, isBoolean } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { LedgerDetail } from 'src/app/components/ledger-details/ledger-details.model';
import { ProxyWsService } from 'src/app/modules/ad-ids/shared/proxy-ws.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { User, User as UserLedgerItem } from 'src/app/services/user/user.model';
import {
  BalanceType,
  BillingActionType,
  BillingActions,
  BillingPlan,
  ConcurrentLimitsBalance,
  TenantBillingDetails,
  TenantSubscriptionsDTO,
  onlineToOfflineWebintActions,
} from 'src/app/shared/models/billing-action.model';
import {
  AvailablePool,
  CreditPools,
  PoolFeatures,
  PoolFeaturesLabelsMap,
} from 'src/app/shared/models/credit-pools.model';
import { AuthService } from '../authentication/auth.service';
import { BaseService } from '../base.service';
import { LedgerService } from '../ledger/ledger.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { TranslationService } from '../translation/translation.service';
import {
  BillingSubscriptionToCreditsLabel,
  BillingSubscriptionToCreditsLabelMap,
} from 'src/app/components/credits-gauge-concurrent/gauge-types';

enum UserBalanceStateMessages {
  CREDITS_EXPIRED = 'Credits are expired',
  NO_CREDITS = 'No credits',
}

enum UserBalanceState {
  CREDITS_EXPIRED = 'CREDITS_EXPIRED',
  NO_CREDITS = 'NO_CREDITS',
  EXISTENT_CREDITS = 'EXISTENT_CREDITS',
}

@Injectable({
  providedIn: 'root',
})
export class UserBillingService extends BaseService {
  private readonly DIGITS_AFTER_DECIMAL_POINT: number = 1;
  private readonly TOPUP_TENANT_EVENT_TYPE: string = 'topup';

  constructor(
    private billingService: BillingService,
    private ledgerService: LedgerService,
    private translationService: TranslationService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private proxyWsService: ProxyWsService,
    protected override router: Router,
    protected override snackBar: MatSnackBar
  ) {
    super(router, snackBar);
    this.authService.isAuthenticated
      .pipe(switchMap(() => this.getTenantDetails()))
      .subscribe(() => this.initializeBillingUpdateSubscriptions());
  }

  private tenantUnassignCredits$: BehaviorSubject<number | CreditPools> =
    new BehaviorSubject<number | CreditPools>(null);
  private tenantCurrentBalance$: BehaviorSubject<number | CreditPools> =
    new BehaviorSubject<number | CreditPools>(null);
  private tenantInitialBalance$: BehaviorSubject<number | CreditPools> =
    new BehaviorSubject<number | CreditPools>(null);
  private tenantBalanceType$: BehaviorSubject<BalanceType> =
    new BehaviorSubject<BalanceType>(null);
  private _concurrentLimitsBalance$: BehaviorSubject<ConcurrentLimitsBalance> =
    new BehaviorSubject<ConcurrentLimitsBalance>({
      initialTargetLimit: 0,
      currentTargetCount: 0,
      initialCaseLimit: 0,
      currentCaseCount: 0,
    });
  public concurrentLimitsBalance$ =
    this._concurrentLimitsBalance$.asObservable();
  private isTenantLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  private isTenantExpired$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  private tenantSubscriptions$: BehaviorSubject<BillingSubscriptionToCreditsLabel> =
    new BehaviorSubject<BillingSubscriptionToCreditsLabel>({});

  private getValidPools(creditPools: CreditPools): string[] {
    return Object.keys(creditPools).filter((pool) => pool !== 'total');
  }

  private isTenantBalanceZero(tenantBalance: TenantBillingDetails): boolean {
    return this.isDistributedBalance()
      ? (tenantBalance.balance as CreditPools).total === 0
      : (tenantBalance.balance as number) === 0;
  }

  private setTenantSubscriptions(): void {
    this.billingService
      .getTenantSubscriptions()
      .subscribe((tenantPoolSubscriptions: TenantSubscriptionsDTO) => {
        const tenantSubs: BillingSubscriptionToCreditsLabel = {};
        Object.keys(tenantPoolSubscriptions).forEach((pool) => {
          tenantSubs[pool] =
            BillingSubscriptionToCreditsLabelMap[tenantPoolSubscriptions[pool]];
        });
        this.tenantSubscriptions$.next(tenantSubs);
      });
  }

  public getTenantSubscriptions(): Observable<BillingSubscriptionToCreditsLabel> {
    return this.tenantSubscriptions$.asObservable();
  }

  private initializeBillingUpdateSubscriptions(): void {
    const billingUpdate$ = this.proxyWsService.getMessage().pipe(
      filter(
        ({ channel }: CQRSBaseEvent<LedgerDetail | UserLedgerItem | Event>) =>
          [EventChannel.Ledger, EventChannel.BillingStatusUpdate].includes(
            channel
          )
      ),
      map((message) => message?.body),
      debounceTime(500)
    );

    const userBillingUpdate$ = billingUpdate$.pipe(
      filter(
        (billingUpdate: LedgerDetail | UserLedgerItem) =>
          billingUpdate?.username ===
          this.localStorageService.getCurrentUser()?.identity
      )
    );

    const tenantBillingUpdate$ = billingUpdate$.pipe(
      filter(
        (billingUpdate: Event) =>
          billingUpdate?.type === this.TOPUP_TENANT_EVENT_TYPE
      )
    );

    userBillingUpdate$.subscribe(() =>
      this.ledgerService.reloadUserLedgerItem()
    );

    tenantBillingUpdate$
      .pipe(switchMap(() => this.getTenantDetails()))
      .subscribe(() => this.ledgerService.reloadUserLedgerItem());
  }

  public getTenantDetails(): Observable<TenantBillingDetails> {
    return this.billingService.getTenantBillingDetails().pipe(
      tap((tenantBalance: TenantBillingDetails) => {
        this.tenantUnassignCredits$.next(tenantBalance.unassignUserCredits);
        this.tenantBalanceType$.next(tenantBalance.balanceType);
        this.tenantCurrentBalance$.next(tenantBalance.balance);
        this._concurrentLimitsBalance$.next(
          tenantBalance.concurrentLimitsBalance
        );
        this.tenantInitialBalance$.next(tenantBalance.initialBalance);
        this.isTenantLoaded$.next(true);
        this.isTenantExpired$.next(this.isTenantBalanceZero(tenantBalance));

        if (this.isDistributedBalance()) {
          this.setTenantSubscriptions();
        }
      })
    );
  }

  public isTenantExpired(): Observable<boolean> {
    return this.isTenantExpired$.asObservable().pipe(
      filter((isExpired) => isBoolean(isExpired)),
      distinctUntilChanged()
    );
  }

  public isTenantExpiredValue(): boolean {
    return this.isTenantExpired$.getValue();
  }

  public getAvailablePools(): AvailablePool[] {
    const tenantUnassignCredits: number | CreditPools =
      this.tenantUnassignCredits$.getValue();
    const pools: AvailablePool[] = [];

    if (this.isDistributedBalance()) {
      this.getValidPools(tenantUnassignCredits as CreditPools).forEach(
        (pool: string) => {
          pools.push({
            value: `${pool}`,
            label: PoolFeaturesLabelsMap[pool],
            unassignCredits: tenantUnassignCredits[pool],
          });
        }
      );
    }

    if (!this.isDistributedBalance()) {
      pools.push({
        value: camelCase(PoolFeatures.DEFAULT),
        label: '' as PoolFeatures,
        unassignCredits: tenantUnassignCredits as number,
      });
    }

    return pools;
  }

  public getTenantUnassignCredits(): number {
    const tenantUnassignCredits: number | CreditPools =
      this.tenantUnassignCredits$.getValue();
    if (this.isDistributedBalance()) {
      return (tenantUnassignCredits as CreditPools).total;
    }
    return tenantUnassignCredits as number;
  }

  public getTenantCurrentBalance(): number {
    const tenantBalanceValue: number | CreditPools =
      this.tenantCurrentBalance$.getValue();
    if (this.isDistributedBalance()) {
      return (tenantBalanceValue as CreditPools).total;
    }
    return tenantBalanceValue as number;
  }

  public hasAvailableConcurrentSlots(type: 'Target' | 'Case'): boolean {
    const count =
      this._concurrentLimitsBalance$.getValue()[`current${type}Count`];
    const limit =
      this._concurrentLimitsBalance$.getValue()[`initial${type}Limit`];
    return count < limit;
  }

  public updateConcurrentLimitsBalance(
    action: 'ADD' | 'REMOVE',
    diff: number,
    type: 'Target' | 'Case'
  ): void {
    const currentData = this._concurrentLimitsBalance$.getValue();

    let updatedValue = currentData[`current${type}Count`];

    switch (action) {
      case 'ADD':
        updatedValue = currentData[`current${type}Count`] + diff;
        break;
      case 'REMOVE':
        updatedValue = currentData[`current${type}Count`] - diff;
        break;
    }

    const updatedData = {
      ...currentData,
      [`current${type}Count`]: updatedValue,
    };

    this._concurrentLimitsBalance$.next(updatedData);
  }

  public getUserCurrentBalance(user: User): number {
    if (this.isTenantExpired$.getValue()) {
      return 0;
    }

    const balance: number | CreditPools | undefined = user.currentBalance;
    return this.getBalanceValueByType(balance);
  }

  public getUserCurrentGeolocationCredits(user: User): number {
    if (this.isTenantExpired$.getValue()) {
      return 0;
    }

    const balance: number | CreditPools | undefined = user.currentBalance;

    if (user.balanceType === 'distributed') {
      return (balance as CreditPools).geolocation;
    } else {
      return this.getBalanceValueByType(balance);
    }
  }

  public getUserInitialBalance(user: User): number {
    const balance: number | CreditPools | undefined = user.initialBalance;
    return this.getBalanceValueByType(balance);
  }

  public getConcurrentStartingBalance(user: User): number {
    const balance: CreditPools | undefined = user.initialBalance as CreditPools;
    if (balance.callLog) {
      balance.total -= balance.callLog;
    }
    return this.getBalanceValueByType(balance);
  }

  public getConcurrentCurrentBalance(user: User): number {
    if (this.isTenantExpired$.getValue()) {
      return 0;
    }

    const balance: CreditPools | undefined = user.currentBalance as CreditPools;
    if (balance.callLog) {
      balance.total -= balance.callLog;
    }
    return this.getBalanceValueByType(balance);
  }

  private getBalanceValueByType(
    balance: number | CreditPools | undefined
  ): number {
    if (!balance) {
      return 0;
    }

    if (!this.isDistributedBalance() && typeof balance === 'number') {
      return this.toDecimal(balance);
    }

    if (this.isDistributedBalance() && typeof balance !== 'number') {
      return this.toDecimal(balance.total);
    }

    if (this.isDistributedBalance() && typeof balance === 'number') {
      return 0;
    }

    throw 'Invalid user balance configured';
  }

  public getTenantUnassignCreditPools(): CreditPools | number {
    return this.tenantUnassignCredits$.getValue();
  }

  public getTenantCurrentBalanceCreditPools(): CreditPools | number {
    return this.tenantCurrentBalance$.getValue();
  }

  public getTenantInitialBalanceCreditPools(): CreditPools | number {
    return this.tenantInitialBalance$.getValue();
  }

  public getTenantBalanceType(): BehaviorSubject<BalanceType> {
    return this.tenantBalanceType$;
  }

  public getTenantBalanceTypeValue(): BalanceType {
    return this.tenantBalanceType$.getValue();
  }

  public isDistributedBalance(): boolean {
    return BalanceType.DISTRIBUTED === this.getTenantBalanceTypeValue();
  }

  public userHasEnoughCredits(actions: BillingActions[]): boolean {
    const userBalanceState: UserBalanceState =
      this.getUserBalanceState(actions);
    if (
      [UserBalanceState.CREDITS_EXPIRED, UserBalanceState.NO_CREDITS].includes(
        userBalanceState
      )
    ) {
      this.showMessage(
        this.translationService.translate(
          UserBalanceStateMessages[userBalanceState]
        )
      );
      return false;
    }
    return true;
  }

  public isOfflineWebintSet(): boolean {
    if (this.isDistributedBalance()) {
      const currentUserBalance = this.ledgerService.getUserLedgerItemValue()
        ?.currentBalance as CreditPools;
      const tenantCurrentBalance =
        this.getTenantCurrentBalanceCreditPools() as CreditPools;

      return (
        'offlineWebint' in tenantCurrentBalance &&
        tenantCurrentBalance.offlineWebint > 0 &&
        currentUserBalance?.offlineWebint > 0
      );
    }
    return false;
  }

  public isSearchOfflineAvailable(): boolean {
    if (this.isDistributedBalance()) {
      const currentBalance: CreditPools =
        this.ledgerService.getUserLedgerItemValue()
          ?.currentBalance as CreditPools;
      return currentBalance?.webint <= 0 && currentBalance?.offlineWebint > 0;
    } else {
      return false;
    }
  }

  public mapOfflineToOnlineCosts(
    plan: BillingPlan<BillingActions, BillingActionType>
  ): BillingPlan<BillingActions, BillingActionType> {
    if (this.isSearchOfflineAvailable()) {
      const updatedPlan = cloneDeep(plan);
      Object.keys(plan).forEach((action) => {
        if (action in onlineToOfflineWebintActions) {
          const offlineAction = onlineToOfflineWebintActions[action];
          updatedPlan[action].cost = plan[offlineAction].cost;
        }
      });
      return updatedPlan;
    }
    return plan;
  }

  private getUserBalanceState(actions: BillingActions[]): UserBalanceState {
    const billingPlan: BillingPlan<BillingActions, BillingActionType> =
      this.billingService.getBillingPlan().getValue();
    const currentBalance: CreditPools | number =
      this.ledgerService.getUserLedgerItemValue()?.currentBalance;
    const creditsExpired: boolean = this.isTenantExpired$.getValue();

    if (creditsExpired) {
      return UserBalanceState.CREDITS_EXPIRED;
    }

    try {
      if (this.isDistributedBalance()) {
        const actionsTypeMap: { [key: string]: BillingActions[] } =
          groupBy(actions);

        const allSatisfied: boolean = actions.every((action) => {
          let actionToBeCharged = action;
          if (
            this.isSearchOfflineAvailable() &&
            action in onlineToOfflineWebintActions
          ) {
            actionToBeCharged = onlineToOfflineWebintActions[action];
          }

          return (
            currentBalance[billingPlan[actionToBeCharged].type] >=
            billingPlan[actionToBeCharged].cost * actionsTypeMap[action].length
          );
        });

        return allSatisfied
          ? UserBalanceState.EXISTENT_CREDITS
          : UserBalanceState.NO_CREDITS;
      } else {
        const total: number = actions.reduce(
          (acc, curr) => acc + billingPlan[curr].cost,
          0
        );
        return typeof currentBalance === 'number' &&
          (currentBalance as number) >= total
          ? UserBalanceState.EXISTENT_CREDITS
          : UserBalanceState.NO_CREDITS;
      }
    } catch (error) {
      return UserBalanceState.NO_CREDITS;
    }
  }

  public isTenantLoaded(): Observable<boolean> {
    return this.isTenantLoaded$.asObservable().pipe(
      filter((isLoaded: boolean) => isLoaded),
      distinctUntilChanged()
    );
  }

  public toDecimal(value: number): number {
    if (!value) {
      return 0;
    }
    return Number(value.toFixed(this.DIGITS_AFTER_DECIMAL_POINT));
  }
}
