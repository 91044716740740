<ul class="step-indexer">
  <ng-container *ngFor="let step of stepTitles; index as i; let last = last">
    <li
      [class.block-click]="stepperOptions?.blockDirectNavigation"
      class="step-indexer__item"
      (click)="onStepSelect(i)"
    >
      <span
        class="step-indexer__item__count"
        [class.step-indexer__item__count--selected]="i === currentStep"
        >{{ i + 1 }}</span
      >
      <span class="step-indexer__item__title">{{ step }}</span>
    </li>
    <li *ngIf="!last" class="step-indexer__split"></li>
  </ng-container>
</ul>
