import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'fe-platform-fa-pro-icon',
  templateUrl: './fa-pro-icon.component.html',
  styleUrls: ['./fa-pro-icon.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaProIconComponent {
  /**
   * Icon family.
   * @type { 'regular' | 'light' | 'thin' | 'duotone' | | 'brands' | 'kit' | undefined }
   */
  @Input()
  public family:
    | 'regular'
    | 'light'
    | 'thin'
    | 'duotone'
    | 'brands'
    | 'kit'
    | 'solid'
    | undefined = undefined;

  /**
   * Sharp icon family.
   * @type { 'regular' | 'light' | 'thin' | 'duotone' | undefined }
   */
  @Input()
  public readonly sharpFamily: 'solid' | 'regular' | 'light' | undefined =
    undefined;

  /**
   * Icon name. Could be found on Fontawesome website.
   * @type { string }
   */
  @Input()
  public name = '';

  /**
   * Spin animation flag.
   * @type { boolean }
   */
  @Input()
  public spin = false;

  /**
   * Pulse animation flag.
   * @type { boolean }
   */
  @Input()
  public readonly pulse: boolean = false;

  /**
   * Reverse flag.
   * @type { boolean }
   */
  @Input()
  public readonly reverse: boolean = false;

  /**
   * Shake animation flag.
   * @type { boolean }
   */
  @Input()
  public readonly shake: boolean = false;

  /**
   * Flip animation flag.
   * @type { boolean }
   */
  @Input()
  public readonly flip: boolean = false;

  /**
   * Bounce animation flag.
   * @type { boolean }
   */
  @Input()
  public readonly bounce: boolean = false;

  /**
   * Fade animation flag.
   * @type { boolean }
   */
  @Input()
  public readonly fade: boolean = false;

  /**
   * Beat animation flag.
   * @type { boolean }
   */
  @Input()
  public readonly beat: boolean = false;

  /**
   * Fade opacity.
   * @type { number }
   */
  @Input()
  public readonly fadeOpacity: number = 0;

  /**
   * Rotate value, deg.
   * @type { number }
   */
  @Input()
  public readonly rotate: number = 0;

  /**
   * Animation duration value.
   * @type { number }
   */
  @Input()
  public readonly animationDuration: number = 0;

  /**
   * Animation duration unit, default 'ms'.
   * @type { number }
   */
  @Input()
  public readonly animationDurationUnit: 's' | 'ms' = 'ms';

  /**
   * Primary color, for Duotone family.
   * @type { string }
   */
  @Input()
  public readonly primaryColor: string = '';

  /**
   * Secondary color, for Duotone family.
   * @type { string }
   */
  @Input()
  public readonly secondaryColor: string = '';

  /**
   * Primary opacity, for Duotone family.
   * @type { number }
   */
  @Input()
  public readonly primaryOpacity: number = 0.4;

  /**
   * Secondary opacity, for Duotone family.
   * @type { number }
   */
  @Input()
  public readonly secondaryOpacity: number = 0.4;
}
