import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { JobType } from 'datalayer/models/background-jobs/background-job-type';
import { BackgroundJob } from 'datalayer/models/background-jobs/background-job.model';
import { Person } from 'datalayer/models/person';
import { DataSource } from 'datalayer/models/platform-models/enums/data.source';
import { EntityRelationType } from 'datalayer/models/platform-models/enums/entity.relation.type';
import { EntityType } from 'datalayer/models/platform-models/enums/entity.type';
import { ImProfile } from 'datalayer/models/platform-models/im-profiles/im-profile';
import { Profile } from 'datalayer/models/platform-models/social/profile';
import { RequestOptions } from 'datalayer/services/base';
import { union } from 'lodash';
import { get } from 'lodash-es';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TargetRecycleBinService } from 'src/app/modules/data-layer/services/recycle-bin/target-recycle-bin.service';
import { IMSeeds } from 'src/app/modules/profiler/shared/models/IM-seeds-data.model';
import { BaseService } from 'src/app/services/base.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Platforms } from 'src/app/shared/models/radical-monitoring-options.model';
import { TargetLink } from 'src/app/shared/models/social-media.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { Platform } from 'src/app/shared/schemas/common/platforms';
import { isMasked, transformSnakeToCamel } from 'src/app/shared/util/helper';
import { environment } from 'src/environments/environment';
import { PersonService } from '../person/person/person.service';
import { ProfileService } from '../social/profile/profile.service';

export interface ImProfilesReqArgsList {
  arg_type: string;
  arg_value: string;
  platform: string;
}

@Injectable({
  providedIn: 'root',
})
export class OsintService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private translationService: TranslationService,
    protected snackBar: MatSnackBar,
    private targetRecycleBinService: TargetRecycleBinService,
    private profileService: ProfileService,
    private personService: PersonService,
    private targetService: TargetService
  ) {
    super(router, snackBar);
  }

  private handleOsintError(e) {
    this.handleError(e);
    if (e.error.error && e.error.error.message) {
      this.showMessage(
        this.translationService.translate(`${e.error.error.message}`)
      );
    }
  }

  public removeDuplicateAndMaskedSeeds(
    queryArgs
  ): { arg_type: string; arg_value: string }[] {
    return queryArgs.reduce((acc, elem) => {
      return !isMasked(elem.arg_value) &&
        acc.findIndex(
          (accElem) =>
            accElem.arg_type === elem.arg_type &&
            accElem.arg_value === elem.arg_value
        ) === -1
        ? [...acc, elem]
        : acc;
    }, []);
  }

  public advancedOsint(options: {
    query_args: { arg_type: string; arg_value: string }[];
    target_id: string;
  }) {
    options.query_args = this.removeDuplicateAndMaskedSeeds(options.query_args);
    if (!options.query_args.length) {
      return of(new Error('No seeds to query'));
    }
    const route = `${environment.fastAPIUri}/osint/advanced`;
    return this.httpClient.post(route, options).pipe(
      catchError((e: HttpErrorResponse) => {
        this.handleOsintError(e);
        return throwError(
          new Error(
            e.status === 429
              ? 'Enhance Profile is already in progress.'
              : e.error.errors[0].message
          )
        );
      })
    );
  }

  public deepOsint(options: {
    query_args: { arg_type: string; arg_value: string }[];
    target_id: string;
  }) {
    options.query_args = this.removeDuplicateAndMaskedSeeds(options.query_args);
    if (!options.query_args.length) {
      return of(new Error('No seeds to query'));
    }
    const route = `${environment.fastAPIUri}/osint/deep`;
    return this.httpClient.post(route, options).pipe(
      catchError((e: HttpErrorResponse) => {
        this.handleOsintError(e);
        return throwError(new Error(e.error.errors[0].message));
      })
    );
  }

  public updateTarget(currentTarget: TargetItem, persons: Person[]) {
    let newTarget;

    if (!currentTarget.userIds) {
      newTarget = { ...currentTarget, userIds: [] };
    } else {
      newTarget = { ...currentTarget };
    }

    persons.forEach((person) =>
      this.updateTargetSocialUrls(currentTarget, newTarget, person)
    );
  }

  async updateTargetSocialUrls(
    currentTarget: TargetItem,
    newTarget: TargetItem,
    profile: Person
  ) {
    // call recycle bin and exlude seeds
    let recycleBinData;
    await this.targetRecycleBinService
      .getTargetRecycleBin(currentTarget.id)
      .toPromise()
      .then((data: { result: { [key: string]: any[] } }) => {
        recycleBinData = data.result;
      });

    if (profile.urls && profile.urls.length) {
      for (const url of profile.urls) {
        if (
          recycleBinData.urls &&
          recycleBinData.urls.length &&
          recycleBinData.urls.includes(url)
        ) {
          continue;
        }

        const links = currentTarget.socialProfiles
          ? currentTarget.socialProfiles.map(
              (socialProfile) => socialProfile.link
            )
          : [];
        if (links.indexOf(url) === -1) {
          await this.updateSocialProfiles(url).then(
            (profileUrl: TargetLink) => {
              if (profileUrl.platform && profileUrl.userId) {
                if (
                  recycleBinData.user_ids &&
                  recycleBinData.user_ids.length &&
                  recycleBinData.user_ids.includes(profileUrl.userId)
                ) {
                  return;
                }

                if (
                  newTarget.socialProfiles.findIndex(
                    (elem: TargetLink) =>
                      elem.userId === profileUrl.userId &&
                      elem.platform === profileUrl.platform
                  ) < 0
                ) {
                  newTarget.socialProfiles.push(profileUrl);
                }
              }
            }
          );
        }
      }
    }

    this.targetService.editProfileUrl(newTarget).subscribe();
  }

  async updateSocialProfiles(url): Promise<TargetLink> {
    // eslint-disable-next-line no-async-promise-executor
    return await new Promise(async (resolve, reject) => {
      const profileUrl: TargetLink = {
        userId: undefined,
        platform: undefined,
        link: url,
      };

      if (url.includes(Platforms.FACEBOOK)) {
        await this.queryFacebookPlatform(url)
          .toPromise()
          .then((id) => {
            profileUrl.userId = id;
            profileUrl.platform = Platform.FACEBOOK;
            resolve(profileUrl);
          });
      }

      if (url.includes(Platforms.INSTAGRAM)) {
        await this.queryInstagramPlatform(url)
          .toPromise()
          .then((id) => {
            profileUrl.userId = id;
            profileUrl.platform = Platform.INSTAGRAM;
            resolve(profileUrl);
          });
      }

      if (url.includes(Platforms.TWITTER)) {
        await this.queryTwitterPlatform(url)
          .toPromise()
          .then((id) => {
            profileUrl.userId = id;
            profileUrl.platform = Platform.TWITTER;
            resolve(profileUrl);
          });
      }

      if (url.includes(Platforms.LINKEDIN) && url.includes('com/in/')) {
        await this.queryLinkedInPlatform(url)
          .toPromise()
          .then((id) => {
            profileUrl.userId = id;
            profileUrl.platform = Platform.LINKEDIN;
            resolve(profileUrl);
          });
      }

      resolve(profileUrl);
    });
  }

  public queryFacebookPlatform(url: string) {
    return this.targetService.getFacebookProfile(url).pipe(
      map((result) => {
        return get(result.data, 'userFbId', '');
      })
    );
  }

  public constructLinkedinQuery(url: string): string {
    const lnSpecialCase = 'profile/view?id=';
    const userId = url.includes(lnSpecialCase)
      ? url.split(lnSpecialCase)[1]
      : url.split('com/in/')[1];
    return userId || '';
  }

  public constructGoogleMapsQuery(url: string): string {
    if (
      !url.match(
        /([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*google.com\/maps\/contrib\/[0-9]{21}$/
      )
    ) {
      return '';
    }

    return url.split('com/maps/contrib/')[1];
  }

  public constructYoutubeQuery(url: string): string {
    let userId = url.split('.com/')[1];
    if (!userId) {
      return '';
    }
    if (userId.includes('channel')) {
      userId = userId.split('channel/')[1];
    } else if (userId.includes('user/')) {
      userId = userId.split('user/')[1];
    }

    return `${userId}@${Platforms.YOUTUBE}`;
  }

  public queryYoutubePlatform(url: string): Observable<string> {
    return of(this.constructYoutubeQuery(url));
  }

  public queryTiktokPlatform(url: string) {
    // url = https://www.tiktok.com/@example_username?lang=en
    let username = url.split('com/@')[1];
    username = username.includes('?') ? username.split('?')[0] : username;
    username = this.handleUsernameWithSlash(username);
    return of(`${username}@tiktok`);
  }

  public queryInstagramPlatform(url: string): Observable<string> {
    // url = www.instagram.com/example_username/ OR url = www.instagram.com/example_username
    let username = url.split('com/')[1]; // username = example_username/ OR username = example_username
    username = this.handleUsernameWithSlash(username);
    return of(`${username}@instagram`);
  }
  public queryGoogleMapsPlatform(url: string): Observable<string> {
    // url = https://www.google.com/maps/contrib/101178641762742223170
    const username = url.split('com/maps/contrib/')[1]; // username = 101178641762742223170
    return of(`${username}@${Platform.DBSEARCH}`);
  }

  public queryTwitterPlatform(url: string): Observable<string> {
    // url = www.twitter.com/example_username/
    // OR url = https://twitter.com/intent/user?screen_name=example_username
    // OR url = https://twitter.com/i/user/XXXX?user_id=XXXX
    /* eslint-disable-next-line no-useless-escape */
    const match = url.match(/\/([^\/?#]+)[^\/]*$/);
    let q = match[1];
    if (match[0] && match[0].includes('/user?')) {
      if (match[0].includes('?screen_name=')) {
        q = match[0].split('?screen_name=')[1];
      } else if (match[0].includes('?user_id=')) {
        q = match[0].split('?user_id=')[1];
      }
    }
    return of(`${q}@twitter`);
  }

  public queryLinkedInPlatform(url: string): Observable<string> {
    // url = www.linkedin.com/in/example_useranme/ OR url = www.linkedin.com/in/example_useranme OR url = www.linkedin.com/profile/view?id=example
    let username = this.constructLinkedinQuery(url);
    username = this.handleUsernameWithSlash(username);
    return of(`${username}@linkedin`);
  }

  private handleUsernameWithSlash(username: string): string {
    if (username?.includes('/')) {
      username = username.split('/')[0]; // username = example_username
    }
    return username;
  }

  getUniqueValues(array: string[]) {
    const uniqueValues = array.reduce((acc, elem, index, arr) => {
      for (const e of arr) {
        if (e && elem) {
          if (
            e.toLocaleLowerCase().includes(elem.toLocaleLowerCase()) &&
            elem !== e
          ) {
            acc[elem] = true;
            break;
          } else {
            acc[elem] = false;
          }
        }
      }
      return acc;
    }, {});

    return Object.keys(uniqueValues).filter((e) => !uniqueValues[e]);
  }

  fetchBackgroundJobs(targetId: string): Observable<BackgroundJob[]> {
    const route = `${environment.fastAPIUri}/background-jobs/`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('target_id', targetId);
    queryParams = queryParams.append('job_type', JobType.ADVANCED_OSINT);
    queryParams = queryParams.append('job_type', JobType.BASIC_OSINT);
    queryParams = queryParams.append('job_status', JobStatus.PENDING);

    return this.httpClient.get<BackgroundJob[]>(route, {
      params: queryParams,
    });
  }

  public fetchOsintPendingJobs(username: string): Observable<BackgroundJob[]> {
    const route = `${environment.fastAPIUri}/background-jobs/`;
    let queryParams = new HttpParams();

    queryParams = queryParams.append('username', username);
    queryParams = queryParams.append('job_type', JobType.ADVANCED_OSINT);
    queryParams = queryParams.append('job_type', JobType.BASIC_OSINT);
    queryParams = queryParams.append('job_status', JobStatus.PENDING);

    return this.httpClient.get<BackgroundJob[]>(route, {
      params: queryParams,
    });
  }

  fetchBackgroundJobsStatusForAnalysis(
    targetId: string
  ): Observable<BackgroundJob[]> {
    const route = `${environment.fastAPIUri}/background-jobs/`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('target_id', targetId);
    queryParams = queryParams.append('job_type', JobType.ADVANCED_OSINT);
    queryParams = queryParams.append('job_type', JobType.BASIC_OSINT);

    queryParams = queryParams.append('job_status', JobStatus.PENDING);
    queryParams = queryParams.append('job_status', JobStatus.RUNNING);

    return this.httpClient.get<BackgroundJob[]>(route, {
      params: queryParams,
    });
  }

  /**
   *
   * @param platforms ImProfilesReqArgsList[]
   * @param queryId string
   */
  public fetchInstantMessagesProfile(
    platforms: ImProfilesReqArgsList[],
    queryId?: string
  ): Observable<ImProfile | ImProfile[]> {
    const queryArgs = {
      query_args: platforms,
    };
    return this.httpClient
      .post<{ result: ImProfile | ImProfile[] }>(
        `${environment.fastAPIUri}/osint/im-profiles`,
        queryId ? { ...queryArgs, geo_query_id: queryId } : queryArgs
      )
      .pipe(map((v) => transformSnakeToCamel(v.result)));
  }

  public enrichTarget(target: TargetItem): Observable<TargetItem> {
    const personFilter: RequestOptions = {
      filters: {
        source: [DataSource.SocialSearch, DataSource.DBSearch],
        targetId: target.id,
        type: EntityType.Person,
        relationType: [EntityRelationType.Plain],
      },
    };
    const IMfilter: RequestOptions = {
      filters: {
        source: [
          DataSource.Skype,
          DataSource.WhatsApp,
          DataSource.Telegram,
          DataSource.Truecaller,
          DataSource.CallerID,
          DataSource.Viber,
          DataSource.Tinder,
          DataSource.IndoDB,
          DataSource.GetContact,
        ],
        targetId: target.id,
        type: EntityType.Profile,
      },
    };
    return forkJoin([
      this.personService.getAll(personFilter),
      this.profileService.getAll(IMfilter),
    ]).pipe(
      map(
        ([people, profiles]: [
          { [key: string]: Person },
          { [key: string]: Profile }
        ]) => {
          this.mergePersonToTargetObject(target, Object.values(people));
          this.mergeIMdataToTargetObject(
            target,
            this.collectSeedsFromProfiles(Object.values(profiles))
          );
          return target;
        }
      )
    );
  }

  private mergeIMdataToTargetObject(
    target: TargetItem,
    data: IMSeeds
  ): TargetItem {
    for (const field of ['name', 'emails', 'usernames']) {
      this.concatDataToTargetArrayField(target, field, data[field]);
    }
    return target;
  }

  private mergePersonToTargetObject(
    target: TargetItem,
    people: Partial<Person>[]
  ): TargetItem {
    for (const person of people) {
      for (const field of [
        'telnos',
        'names',
        'countries',
        'emails',
        'usernames',
        'landTelnos',
        'userIds',
      ]) {
        this.concatDataToTargetArrayField(target, field, person[field]);
      }
      if (person.addresses.length) {
        const displayAddresses = person.addresses.map(
          (e: { display: string; country: string }) => {
            let displayAddress = `${e.display}`;
            if (e.country) {
              displayAddress += `, ${e.country}`;
            }
            return displayAddress;
          }
        );
        target.addresses = [
          ...this.getUniqueValues(union(target.addresses, displayAddresses)),
        ];
      }

      if (!target.gender) {
        target.gender = person.gender;
      }
    }
    return target;
  }

  private concatDataToTargetArrayField(
    target: TargetItem,
    field: string,
    data: Array<string | number> = []
  ): TargetItem {
    target[field] = (target[field] ?? []).concat(
      data.filter((value: string | number) => target[field]?.indexOf(value) < 0)
    );
    return target;
  }

  private collectSeedsFromProfiles(profiles: Profile[]): IMSeeds {
    const data: IMSeeds = {
      name: [],
      username: [],
      email: [],
      gender: '',
    };

    profiles.forEach((profile) => {
      Object.keys(data).forEach((key) => {
        if (
          Array.isArray(data[key]) &&
          profile[key] &&
          profile[key] !== ' ' &&
          data[key].findIndex(
            (dataElem: string) =>
              dataElem.toLocaleLowerCase().trim() ===
              <string>profile[key].toLocaleLowerCase().trim()
          ) < 0
        ) {
          data[key].push(profile[key]);
        }
        if (
          !Array.isArray(data[key]) &&
          profile[key] &&
          profile[key] !== ' ' &&
          data[key] !== profile[key]
        ) {
          data[key] = profile[key];
        }
      });
    });
    return data;
  }

  createIMProfiles(targetIds: string[], imProfiles: Profile[]) {
    return this.httpClient
      .post<any>(`${this.fastAPIurl}/osint/create-im-profiles`, {
        profiles: imProfiles,
        target_ids: targetIds,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }
}
