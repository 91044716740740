export enum AdIntSessionQueryStatus {
  Complete = 'complete',
  QueryFailed = 'query_failed',
  ProcessingFailed = 'processing_failed',
}

export interface AdIntSessionQueryEvent {
  caseId: string | null;
  sessionId: string;
  status: AdIntSessionQueryStatus;
}

export interface AdIntWSMessage<T> {
  type: string;
  tenant_id: string;
  user_id: string;
  payload: T;
}
