import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { camelCase } from 'lodash-es';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import {
  BillingActions,
  BillingActionType,
  BillingPlan,
  TenantBillingDetails,
  TenantBillingDetailsDTO,
  TenantSubscriptionsDTO,
} from 'src/app/shared/models/billing-action.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { AuthService } from '../authentication/auth.service';
import { AuthState } from '../authentication/auth-state.enum';

@Injectable({
  providedIn: 'root',
})
export class BillingService extends BaseService {
  private readonly TEN_MINUTES = 1000 * 60 * 10;
  private readonly billingPlan$ = new BehaviorSubject<BillingPlan<
    BillingActions,
    BillingActionType
  > | null>(null);
  private readonly isLoggedOut$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    protected override router: Router,
    protected override snackBar: MatSnackBar
  ) {
    super(router, snackBar);
    this.authService.authState$.subscribe((state) => {
      if (state === AuthState.authenticated) {
        this.startBillingPlanTimer();
      } else {
        this.isLoggedOut$.next();
      }
    });
  }

  private startBillingPlanTimer() {
    timer(0, this.TEN_MINUTES)
      .pipe(
        switchMap((_) =>
          this.httpClient.get(`${this.fastAPIurl}/billing-plan`)
        ),
        map((billingPlan: BillingPlan<BillingActions, BillingActionType>) => {
          Object.keys(billingPlan).forEach((action: BillingActions) => {
            billingPlan[action].type = camelCase(
              billingPlan[action].type.toLocaleLowerCase()
            ) as BillingActionType;
            billingPlan[action].cost = Number(billingPlan[action].cost);
          });
          this.billingPlan$.next(transformSnakeToCamel(billingPlan));
        }),
        catchError((error) => this.handleError(error)),
        takeUntil(this.isLoggedOut$)
      )
      .subscribe();
  }

  public getBillingPlan(): BehaviorSubject<
    BillingPlan<BillingActions, BillingActionType>
  > {
    return this.billingPlan$;
  }

  public getTenantBillingDetails(): Observable<TenantBillingDetails> {
    return this.httpClient
      .get(`${this.fastAPIurl}/billing/tenant-details`)
      .pipe(
        catchError(() => of({ result: <TenantBillingDetailsDTO>{} })),
        map((data: { result: TenantBillingDetailsDTO }) =>
          transformSnakeToCamel(data.result)
        )
      );
  }

  public getTenantSubscriptions(): Observable<TenantSubscriptionsDTO> {
    return this.httpClient
      .get(`${this.fastAPIurl}/billing/tenant-subscriptions`)
      .pipe(
        catchError(() => of({ result: {} })),
        map((data: { result: TenantSubscriptionsDTO }) =>
          transformSnakeToCamel(data.result)
        )
      );
  }
}
