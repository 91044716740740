import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { Observable, catchError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Country } from '@shared/models/country.model';
import { Region } from '@shared/models/region.model';

@Injectable({
  providedIn: 'root',
})
export class InvestigationFiltersHelpersService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  public fetchCountries(msisdns: string[]): Observable<Country[]> {
    const url = `${this.proxyApiUrl}/call-logs/countries/`;
    return this.httpClient
      .post<Country[]>(url, { msisdns })
      .pipe(catchError((error) => of(error)));
  }

  public fetchRegions(msisdns: string[]): Observable<Region[]> {
    const url = `${this.proxyApiUrl}/call-logs/regions`;
    return this.httpClient
      .post<Region[]>(url, { msisdns })
      .pipe(catchError((error) => of(error)));
  }
}
