import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition,
  MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
} from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
  LayoutService,
} from '@fe-platform/shared-ui/intellectus';
import {
  DEFAULT_SITE_SETTINGS,
  LanguageOptions,
} from '@shared/models/site-settings.model';
import { Observable, Subject, Subscription, firstValueFrom } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { OTPChannels } from 'src/app/components/user-settings/user-settings.models';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { AuthDataService } from 'src/app/services/authentication/auth-data.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { SignInErrors } from 'src/app/services/authentication/dto';
import { AuthPayload } from 'src/app/services/authentication/types';
import { QuestionnaireService } from 'src/app/services/questionnaire/questionnaire.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { User } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { Animations } from 'src/app/shared/animations/animations';
import { FormState, LoginState } from 'src/app/shared/models/login.model';
import { Skin, Skins, Themes } from 'src/app/shared/models/skins.model';
import Swal from 'sweetalert2';
import { InputStatus } from '../user-settings/user-settings.models';
import { ThemeHelper } from '@shared/util/theme.helper';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.scss'],
  animations: [Animations.openCloseLoginPanel],
})
export class LoginComponent implements OnInit, OnDestroy {
  defaultLanguages = DEFAULT_SITE_SETTINGS.languages;
  initialLanguage = this.translationService.language;
  errorMsg = '';
  loginForm: UntypedFormGroup;
  subscription: Subscription;
  loginState: LoginState = LoginState.ENTERING_PASSWORD;
  enabledRecaptcha: boolean;
  theme: Themes;
  themeClasses: { [key in Themes]: string } = {
    [Themes.GEOLOC]: 'geoloc-login',
    [Themes.WHITE]: '',
    [Themes.GEO]: '',
    [Themes.FRESHVALE]: 'freshvale-login',
    [Themes.UNLIMITED]: '',
    [Themes.GENERSHIELD]: 'genershield-login',
    [Themes.CLARITY]: '',
  };
  isWhiteTheme = false;
  cvtpFlavor = false;
  @ViewChild('password', { static: true })
  password: ElementRef<HTMLInputElement>;
  passError = false;
  userError = false;
  supportEmail: string;
  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';
  passwordVisibility = false;
  formState: FormState = FormState.LOGIN;
  skin: Skin;
  showLoader = false;
  currentUser: User;
  passwordFormValue: InputStatus = { value: '', valid: false };
  phoneFormValue: InputStatus = { value: '', valid: false };
  emailFormValue: InputStatus = { value: '', valid: false };
  otpFormValue: InputStatus = { value: '', valid: false };

  public otpChannels = OTPChannels;
  public formStates = FormState;

  public verification: {
    isNewUser?: boolean;
    is2faEnforced?: boolean;
    is2faRequired?: boolean;
    is2faSelection?: boolean;
    is2faSelectionRadioVisible?: boolean;
    is2faResetEnabled?: boolean;
    isPasswordResetRequired?: boolean;
    isResetting2Fa?: boolean;
    reset2faRecipient?: string;
    recipient?: string;
    channel?: OTPChannels;
  } = {};

  public formStateTitle: { [key in FormState]: string } = {
    [FormState.LOGIN]: 'Login',
    [FormState.CHANGE_PASSWORD]: 'Change your password',
    [FormState.CONFIGURE_VERIFICATION]: this.verification.is2faEnforced
      ? '2-Step Verification is OFF'
      : 'Activate 2-step verification',
    [FormState.VERIFICATION]: '2-Step Verification',
    [FormState.VERIFY_OTP]: 'Verify OTP',
  };

  public fAavailableIcons: { [name: string]: FontAwesomeIcon } = {
    eyeVisible: {
      name: 'eye',
      family: FontAwesomeFamily.REGULAR,
    },
    eyeHidden: {
      name: 'eye-slash',
      family: FontAwesomeFamily.REGULAR,
    },
  };
  public isMobile$: Observable<boolean> = this.layoutService.isMobile$;

  private destroy$ = new Subject<void>();

  constructor(
    protected router: Router,
    protected applicationStateService: ApplicationStateService,
    protected appConfigService: AppConfigService,
    protected translationService: TranslationService,
    public dialog: MatDialog,
    protected localStorageService: LocalStorageService,
    private authSessionService: AuthService,
    private authService: AuthDataService,
    private questionnaireService: QuestionnaireService,
    private cdRef: ChangeDetectorRef,
    private userService: UserService,
    private layoutService: LayoutService,
    public snackBar?: MatSnackBar
  ) {
    this.enabledRecaptcha =
      this.appConfigService.getConfigVariable('enabledRecaptcha');
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.cvtpFlavor = this.applicationStateService.getSkin() === Skins.CVTP;
    this.skin = this.appConfigService.getConfigVariable('flavor');
  }

  ngOnInit() {
    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    this.showLoader = false;
    this.initForm();

    const configLanguage = <LanguageOptions>(
      this.appConfigService.getConfigVariable('defaultLanguage')
    );
    const language = <LanguageOptions>this.localStorageService.getLangauge();

    this.languageChanged(language || configLanguage);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm() {
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl(''),
      password: new UntypedFormControl(''),
      otp_code: new UntypedFormControl(''),
    });
  }

  public async onSubmit() {
    this.clearErrorMessage();
    if (!this.loginForm.valid) {
      this.passError = !this.loginForm.value.password;
      this.userError = !this.loginForm.value.username;
      return;
    }
    this.showLoader = true;

    this.authSessionService
      .login(await this.getAuthPayload())
      .subscribe(this.authResponseObserver);
  }

  private get authResponseObserver() {
    return {
      next: () => {
        if (this.appConfigService.getConfigVariable('enableTermsOfUse')) {
          this.router.navigate(['terms-of-use']);
        } else {
          this.postLoginFunctionality();
        }
      },
      error: (error) => {
        this.showLoader = false;
        this.handleError(error);
      },
    };
  }

  private async handleError(error: SignInErrors) {
    this.verification.is2faRequired = !!error?.otp_code_required;
    this.verification.is2faEnforced = !!error?.force_2fa_enablement;
    this.verification.is2faResetEnabled = !!error?.can_reset_2fa;
    this.errorMsg = error?.message;

    if (error?.otp_code_required) {
      if (this.formState !== FormState.CHANGE_PASSWORD) {
        if (this.formState === FormState.CONFIGURE_VERIFICATION) {
          this.verification.is2faEnforced = true;
        }
        this.formState = FormState.VERIFICATION;
      }
      this.verification.is2faSelection = false;
      this.verification.recipient = error?.otp_recipient;
      this.verification.channel = error?.otp_channel;
    } else if (error?.request_password_change) {
      this.verification.isPasswordResetRequired = true;
      if (!this.currentUser) {
        this.currentUser = await this.getCurrentUser();
        this.verification.isNewUser = !this.currentUser?.firstLoginAt;
      }

      this.verification.is2faSelection =
        this.verification?.is2faEnforced ||
        this.currentUser?.otpEnabled ||
        this.verification?.isNewUser;

      if (this.formState === FormState.VERIFICATION) {
        this.verification.is2faSelection = false;
        this.verification.is2faRequired = false;
      }
      this.formState = FormState.CHANGE_PASSWORD;
    } else if (error?.force_2fa_enablement) {
      this.formState = FormState.CONFIGURE_VERIFICATION;
    } else if (error?.support_email_required) {
      this.showSupportEmailPopUp();
    } else {
      this.verification = {};
      this.formState = FormState.LOGIN;
    }

    this.showLoader = false;
    this.cdRef.markForCheck();
  }

  public async onSelectOtpMethodApply(): Promise<void> {
    await this.otpRequest();
    this.cdRef.markForCheck();
  }

  public async onSubmitOtpCode({ resetPassword = false } = {}): Promise<void> {
    this.showLoader = true;

    if (await this.otpRequest()) {
      if (resetPassword) {
        return this.resetUserPassword();
      }
      this.authSessionService.reload().subscribe(() => {
        this.router.navigate([
          this.authSessionService.getDefaultRedirectRoute(),
        ]);
      });
    }
    this.showLoader = false;
    this.cdRef.markForCheck();
  }

  private async otpRequest(): Promise<boolean> {
    try {
      const { status, ...errors } = await firstValueFrom(
        this.authService.sendCode({
          password: this.loginForm.controls['password'].value,
          otp_channel: this.verification.channel ?? OTPChannels.None,
          otp_phone: this.phoneFormValue.value || undefined,
          otp_email: this.emailFormValue.value || undefined,
          otp_code: this.otpFormValue.value,
          otp_status: true,
        })
      );

      if (status === 'ok') {
        return true;
      } else {
        this.handleError(errors);
      }
    } catch (error) {
      this.handleError(error);
    }
    return false;
  }

  public resetUserPassword(): void {
    const currentPassword = this.loginForm.controls['password'].value;
    const newPassword = this.passwordFormValue.value;
    this.showLoader = true;
    this.userService
      .resetPassword(currentPassword, newPassword)
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.showLoader = false;
          this.formState = FormState.CHANGE_PASSWORD;
          return this.handleError(error);
        })
      )
      .subscribe(() => {
        this.authSessionService
          .refresh()
          .pipe(takeUntil(this.destroy$))
          .subscribe(this.authResponseObserver);
      });
  }

  public onChannelChanged($event: OTPChannels): void {
    this.verification.channel = $event;
  }

  public onPhoneChanged($event: InputStatus): void {
    this.phoneFormValue = $event;
    this.verification.recipient = $event.value;
  }

  public onEmailChanged($event: InputStatus): void {
    this.emailFormValue = $event;
    this.verification.recipient = $event.value;
  }

  public onOTPCodeChanged($event: InputStatus): void {
    this.otpFormValue = $event;
  }

  public onPasswordFormValidationChange($event: InputStatus): void {
    this.passwordFormValue = $event;
  }

  public onRadioVisibilityChange($event: boolean) {
    this.verification.is2faSelectionRadioVisible = $event;
  }

  public languageSelectorTriggered(event: MatSelectChange) {
    const language = <LanguageOptions>event.value;
    this.languageChanged(language);
    this.cdRef.markForCheck();
  }

  public languageChanged(language: LanguageOptions) {
    this.localStorageService.setLanguage(language);
    this.translationService.language = language;
    this.initialLanguage = language;
    this.translationService.languageChange.next(language);
  }

  public onUserChangedClicked(): void {
    this.onBackToLogin();
  }

  public async onReset2FAVerification(): Promise<void> {
    if (!this.verification.is2faResetEnabled) {
      return;
    }
    this.verification.isResetting2Fa = true;
    this.showLoader = true;
    this.cdRef.markForCheck();
    this.authService
      .requestEmailAuthenticatorQrCode(await this.getAuthPayload())
      .subscribe(
        (email: string) => {
          this.verification.reset2faRecipient = email;
          this.showLoader = false;
          this.cdRef.markForCheck();
        },
        () => {
          this.showMessage(
            this.translationService.translate(
              'Something went wrong. Please try OTP reset again.'
            )
          );
          this.verification.isResetting2Fa = true;
          this.showLoader = false;
          this.cdRef.markForCheck();
        }
      );
  }

  public onBackToLogin() {
    this.formState = FormState.LOGIN;
    this.loginState = LoginState.ENTERING_PASSWORD;
    this.supportEmail = undefined;
    this.verification = {};
    this.loginForm.controls['password'].setValue('');
    this.loginForm.controls['otp_code'].setValue('');
    this.loginForm.controls['username'].setValue('');
    this.clearErrorMessage();
  }

  public getLogo(isMobile: boolean): string {
    if (this.cvtpFlavor) {
      return 'assets/static/images/cvd-logo.svg';
    }
    return ThemeHelper.getLogo(this.theme, isMobile);
  }

  protected showSupportEmailPopUp(): void {
    Swal.fire({
      title: this.translationService.translate('Login'),
      text: this.translationService.translate(
        'Please provide your email address'
      ),
      input: 'email',
      showCancelButton: true,
      confirmButtonText: this.translationService.translate('Send'),
      confirmButtonColor: '#314a46',
      cancelButtonText: this.translationService.translate('Cancel'),
      validationMessage: this.translationService.translate(
        'Invalid email address'
      ),
      // classes for automation suite
      customClass: {
        input: 'data-qa-support-email-popup-input',
        confirmButton: 'data-qa-support-email-popup-confirm-button',
      },
    }).then((email: any) => {
      if (email.value) {
        this.handleSupportEmail(email.value);
      }
    });
  }

  private handleSupportEmail(email: string): void {
    email = email.toLowerCase();
    const domain =
      this.appConfigService.getConfigVariable('supportEmailDomain');

    try {
      if (!email.endsWith(`@${domain}`)) {
        throw void 0;
      }

      const username = email.substring(0, email.indexOf('@'));
      if (
        ['support', 'qa'].some((keyword) => username.indexOf(keyword) !== -1)
      ) {
        throw void 0;
      }

      this.supportEmail = email;
      this.onSubmit();
    } catch (error) {
      this.showMessage(
        this.translationService.translate('Please enter a valid email!')
      );
    }
  }

  private async getCurrentUser(): Promise<User> {
    const user = await firstValueFrom(this.userService.getCurrentUser());
    this.currentUser = user;
    this.cdRef.markForCheck();
    return user;
  }

  private showMessage(
    msg: string,
    okText = 'OK',
    preferredDuration?: number,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition
  ) {
    this.snackBar.open(msg, okText, {
      duration: preferredDuration || 3000,
      horizontalPosition: preferredHorizontalPosition,
      verticalPosition: preferredVerticalPosition,
      panelClass: ['custom-snackbar'],
    });
  }

  private clearErrorMessage() {
    this.errorMsg = '';
    this.passError = false;
    this.userError = false;
  }

  private async getAuthPayload(): Promise<AuthPayload> {
    return {
      username: this.loginForm.controls['username'].value.toLowerCase(),
      password: this.loginForm.controls['password'].value,
      otp_code:
        this.otpFormValue.value || this.loginForm.controls['otp_code'].value,
      ...(this.supportEmail ? { support_email: this.supportEmail } : {}),
      ...(this.authSessionService.recaptcha.isEnabled
        ? {
            recaptchaToken: await this.authSessionService.recaptcha.token(
              'login'
            ),
          }
        : {}),
    };
  }

  private postLoginFunctionality(): void {
    this.questionnaireService.displayQuestionnaireDialog();
    this.router.navigate([this.authSessionService.getDefaultRedirectRoute()]);
  }
}
